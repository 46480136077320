
<template>
{{ modelOnScene }}
  <canvas id="renderCanvas2" touch-action="none"></canvas>
</template>

<script>
import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import 'babylonjs-loaders';

export default {
  name: 'MyScene2',
  components: {
  },
  data(){
    return{
      propToWatch:true,
      scene:null,
      armrestslegsSet:null,
      legsNodes:null,
      legsMehses:null,
      showLeftExtendIcon:null,
      showRightExtendIcon:null,
      legOriginalNode:null,
      GUIAssets:{},
      importedModel:{},
      modelOnScene:{},
      nodesInstanceCounter:0,
      numberOfAllInstances:0,
      positionOfModelsMergePoint: new BABYLON.Vector3.Zero(),
      notRotebleLegs : ['n51', 'n53'],
      highlightedModel:{},
      pickedAddbutton:{},
      pickedAddAccbutton:{},
      positionOfAccMergePoint:null,
      armrests:{},
      endparts:{},
      instancesIds:[],
      instances:[],
      legInstancesContainer:[]
      
    }
  },
  created(){
  },
  mounted(){
    this.initializeEngine()
  },
  computed:{
    extraRotationValue(){
      var rotation = 0
      try{
        if( this.pickedAddbutton.name.includes('!R') ){
          var Rvalue = parseInt( this.pickedAddbutton.name.split("!R")[1] )
          rotation = Rvalue *-1
        }
      }catch(e){
        // pass
      }

      return rotation
    },
    sideToExtend(){
      var addBtn = null
      try{
        addBtn = this.pickedAddbutton['name'].split('_')[ this.pickedAddbutton['name'].split('_').length-1 ]
      }catch(e){  
        // pass
      }

      return addBtn
    },
    armrestPositionX(){
      var armrestPositionX = 0
      try{
        armrestPositionX = this.armrests['RIGHT'].position.x
      }catch(e){
        // pass
      }

      return armrestPositionX
    },
    sceneIsReady(){
      var isReady = false
      try{
        isReady = this.scene.isReady()
      }catch(e){
        isReady = false
      }
      if( isReady ){
        this.hideModelLegWithArmrest()
      }
      return isReady
    },
    legModel(){
      return this.$parent.selectedLegs.legModelName
    }
  },
  methods:{
    initializeEngine(){
      // Get the canvas DOM element
      this.canvas = document.getElementById('renderCanvas2');
      
      // Load the 3D engine
      var engine = new BABYLON.Engine(this.canvas, true, {doNotHandleContextLost: true} );
      this.engine = engine
      this.engine.enableOfflineSupport = false;
      this.engine.doNotHandleContextLost = true;


      // call the createScene function
      var scene = this.createScene();

      // make helper box ( for placeholder and camera track)
      // set camera target to moveable hidden object to set the transition
      this.createboxesTargetCamera()
      // this.camera.setTarget( this.boxTargetCamera );
      
      // run the render loop
      this.engine.runRenderLoop(function(){
          scene.render();
      });

      // the canvas/window resize event handler
      window.addEventListener('resize', function(){
          engine.resize();
      });

      // debug layer
      scene.debugLayer.show()

      // the highlight layer.
      this.highlightLayer = new BABYLON.HighlightLayer("highlight", scene);
      
      // gui button 
      this.createGUIButton()

      // change screen color to transparent
      scene.clearColor  = new BABYLON.Color4(0, 0, 0, 0);

      // actionManager
      this.actionManager = new BABYLON.ActionManager(scene);

      // on mesh click
      var onMeshClick = this.onMeshClick
      var disposeGUIButtonForPickedModelAndRemoveEgdes = this.disposeGUIButtonForPickedModelAndRemoveEgdes
      scene.onPointerDown = function (evt, pickResult) {
        if(evt.button  == 2 ){
          // right click
          // free rotate camera
        }else if( evt.button == 0 && pickResult.pickedMesh != undefined ){
          onMeshClick( pickResult.pickedMesh )
        }else if( evt.button == 0 ){
          disposeGUIButtonForPickedModelAndRemoveEgdes()
        }
      }

      // optimalization
      scene.getAnimationRatio();
      
      this.scene = scene

    },
    createScene(){
      // Create a basic BJS Scene object
      var scene = new BABYLON.Scene(this.engine);

      // Parameters: name, alpha, beta, radius, target position, scene
      var camera = new BABYLON.ArcRotateCamera("Camera", 0, 0, 1, new BABYLON.Vector3(0, .4, 0), scene);
      
      // This positions the camera
      camera.setPosition(new BABYLON.Vector3(0, 2, 4));

      // Change sensivity of mouse whell zoom
      camera.wheelPrecision = 100
      camera.fov = .2
      
      // Attach the camera to the canvas
      camera.attachControl(this.canvas, true);

      // Create a basic light, aiming 0, 1, 0 - meaning, to the sky
      var light  = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), scene);
      light.intensity = 1.3;
      // change scene system
      scene.useRightHandedSystem = true

      scene.autoClear = false; // Color buffer
      scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously
      scene.blockMaterialDirtyMechanism = true;
      scene.getAnimationRatio();

      this.camera = camera
      
      // Return the created scene
      return scene;
    },
    async deleteAllSceneMeshes(){
      
      try{
        this.scene.rootNodes.forEach( node => {
          if( node.name.includes('instance') ||
              node.name.includes('D_')
          ){
            node.dispose()
          }
        })
        this.scene.meshes.forEach( node => {
          if( node.name.includes('LEGS') ||
              node.name.includes('ARMREST') 
          ){
            node.dispose()
          }
        })
      }catch(e){
        // pass
      }

      return 0

      
    },
    createboxesTargetCamera(){
      this.boxTargetCameraFreeMove = BABYLON.MeshBuilder.CreateBox("boxTargetCameraFreeMove", {height: .2 , width: .2 , depth: .2 });
      this.boxTargetCameraFreeMove.isVisible = false
      
      this.boxTargetCamera = BABYLON.MeshBuilder.CreateBox("boxTargetCamera", {height: .1, width: .1, depth: .1});
      this.boxTargetCamera.isVisible = false

      this.dummyBox = BABYLON.MeshBuilder.CreateBox("dummyBox", {height: .01, width: .01, depth: .01});
      this.dummyBox.setEnabled(true)
      this.dummyBox.isVisible = false
      this.dummyBox.convertToFlatShadedMesh();

      this.lastSelestedBtn = this.dummyBox

      this.boxTargetCameraFreeMove.parent = this.boxTargetCamera
      this.camera.parent = this.boxTargetCameraFreeMove

    },
    createGUIButton(){

      var buttons = [ 'add_black', 'add_green', 'remove_black', 'remove_red' ] 
      
      buttons.forEach(btn => {
        var plane = BABYLON.MeshBuilder.CreateDisc(btn, {radius: .1 , sideOrientation: BABYLON.Mesh.DOUBLESIDE } );
        plane.occlusionType = BABYLON.AbstractMesh.OCCLUSION_TYPE_STRICT;
        plane.rotation.x = BABYLON.Tools.ToRadians( 180 )
        plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
        var advancedTexture = new GUI.AdvancedDynamicTexture.CreateForMesh(plane);
        var button = GUI.Button.CreateImageOnlyButton(btn , "../gui/icon-"+btn+".png");
        button.occlusionType = BABYLON.AbstractMesh.OCCLUSION_TYPE_STRICT;
        advancedTexture.addControl(button);
        plane.isVisible = false
        this.GUIAssets[btn] = plane
      });

      

    },
    showGUIButtonForPickedModel(model){
      
      model.getChildren().forEach(child => {
          if( ( child.name.includes('A_ELEMENT') || child.name.includes('A_ACCESSORY') ) ){

            var newButton = this.GUIAssets['add_black'].clone()
            
            // make dummy mesh to has absolute position on node
            var mesh = this.dummyBox.createInstance("BTN");
            mesh.isVisible = false
            mesh.position = child.position.clone()
            mesh.parent = model
            newButton.position = new BABYLON.Vector3.Zero()

            newButton.parent = mesh
            newButton.linkedObject = child
            newButton.isVisible = true

            if( child.takenBy != undefined && child.name.includes('A_ACCESSORY') ){
              newButton.setEnabled(false)
            }
          }
          if( child.name.includes('A_ELEMENT') ){
            newButton.name = 'A_ELE_BTN'
            newButton.position.y += .4
            newButton.scaling = new BABYLON.Vector3(1.2, 1.2, 1.2)

            if( child.name.includes('LEFT') && ( model.leftSideTakenBy != null || this.showLeftExtendIcon == false )  ){
              newButton.parent.dispose()
            }
            if( child.name.includes('RIGHT') && ( model.rightSideTakenBy != null || this.showRightExtendIcon == false )  ){
              newButton.parent.dispose()
            }
            if( this.$parent.selectedData.title == 'Accessories' ){
              newButton.dispose()
            }

          }else if( child.name.includes('A_ACCESSORY') ){
            newButton.name = 'A_ACC_BTN'
            newButton.scaling =  new BABYLON.Vector3(.7, .7, .7)
            newButton.position.y += .1
            newButton.position.z += .02
            if( this.$parent.selectedData.title == 'Elements' ){
              newButton.dispose()
            }
          }
      });

      // remove btn
      if( model.leftSideTakenBy == null || model.rightSideTakenBy == null ){
        var newButton = this.GUIAssets['remove_black'].clone()
        // newButton.renderingGroupId = 2
        newButton.position = new BABYLON.Vector3.Zero()
        newButton.name = 'DELETE_BTN'
        newButton.position.y = model.name.includes('ACCESSORY') ? .3 : 1
        newButton.parent = model.name.includes('ACCESSORY') ? model.parent : model
        newButton.isVisible = true
        newButton.actionManager = this.actionManager;

        // on hover, change btn background color to red
        var GUIAssets = this.GUIAssets
        newButton.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function(ev){
                newButton.material = GUIAssets['remove_red'].material
        }));
        newButton.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
        function (AM_event) {
            newButton.material = GUIAssets['remove_black'].material
        }));
      }

    },
    disposeGUIButtonForPickedModelAndRemoveEgdes(){
      try{
        // reset selected btn
        this.removeHighlightPickedModel()
      }catch(e){

      }
    },
    onMeshClick(model){
      var elementsAddScreenName = ['Elements' , 'Accessories']
      if( !elementsAddScreenName.includes( this.$parent.selectedData.title )  || !this.$parent.otherViewActive ){
        return 0
      }
      var parent = model.parent

      // Delete if delete btn was picked
      if( model.name == "DELETE_BTN" ){
        var typeSybmdol = parent.furnitureTypeSymbol ? parent.furnitureTypeSymbol : parent.getChildren()[0].furnitureTypeSymbol
        this.modelOnScene[typeSybmdol].quantity --
        var modelToDispose = parent.furnitureTypeSymbol ? parent : parent.getChildren()

        // ACCESSORY
        if( Array.isArray(modelToDispose) ){
          modelToDispose.forEach(element => {
            element.dispose()
          });
          parent.takenBy = undefined
        }else{
          // ELEMENT
          this.unsetParent()
          modelToDispose.dispose()
          this.setFreeTakenSideAfterElementDelete(parent.instanceId)
        }

        this.$parent.orderLineSet('Update')
      }
      // if model button picked
      else if( model.name.includes('BTN') ){
          parent.getChildren().forEach(child => {
              if( child.name.includes("BTN") ){
                child.material = this.GUIAssets['add_black'].material
                if( child == model  ){

                  this.lastSelestedBtn.material = this.GUIAssets['add_black'].material
                  child.material = this.GUIAssets['add_green'].material
                  this.lastSelestedBtn = child
                  
                }else{
                  child.material = this.GUIAssets['add_black'].material
                }
                if( child.name.includes( "DELETE_BTN" ) ){
                  child.material = this.GUIAssets['remove_black'].material
                }

              }
          });
          // change positionOfModelsMergePoint
          if( model.name == 'A_ELE_BTN' ){
            this.pickedAddbutton = model.linkedObject
            this.positionOfModelsMergePoint = model.absolutePosition
         }
          if( model.name == 'A_ACC_BTN' ){
            this.pickedAddAccbutton = model.linkedObject
            this.positionOfAccMergePoint = model.absolutePosition
         }
      }else{
        if( this.highlightedModel.highlightedInstanceId != parent.instanceId ){
          this.disposeGUIButtonForPickedModelAndRemoveEgdes()
          this.highlightPickedModel(parent)
          this.highlightedModel  = parent
          this.highlightedModel.highlightedInstanceId = parent.instanceId
          var pickedModelRules = this.$parent.familyElements.filter(el=>{
            return el['furnitureTypeSymbol'] == parent.furnitureTypeSymbol
          })
          if( pickedModelRules.length > 0 ){
              this.showRightExtendIcon = pickedModelRules[0]['isLeftExtend']
              this.showLeftExtendIcon = pickedModelRules[0]['isRightExtend']
          }
          this.showGUIButtonForPickedModel( parent )
        
        }else{
          this.disposeGUIButtonForPickedModelAndRemoveEgdes()
          this.highlightedModel.highlightedInstanceId = -1
        }
      }
    },
    removeHighlightPickedModel(){
      this.pickedAddbutton = null
      this.highlightedModel.getChildren().forEach(child => {
        if( child.name.includes('BTN') ){
          child.dispose()
        }else if( child.getClassName() == "InstancedMesh" ){
          child.disableEdgesRendering()
        }
      });
      this.highlightedModel.highlightedInstanceId = -1
    },
    highlightPickedModel(parent){
          parent.getChildren().forEach(child => {
              if( child.getClassName() == "InstancedMesh" ){
                child.enableEdgesRendering()
                child.edgesWidth = 3
                child.edgesColor = new BABYLON.Color4( .1, 1, .1 , 1 );
              }
          });

    },
    changeTexturesForNode( node, type='element' ){
      var nodeModelName = node.name.split('_')[1]
      node.getChildren().forEach(element => {
        try{
          if( !element.name.startsWith('A_') ){
            var myMaterial = new BABYLON.StandardMaterial( nodeModelName+element.name , this.scene);
            myMaterial.specularColor = new BABYLON.Color3(0.1, 0.1, 0.1);
            element.material = myMaterial
          }
        }catch(e){
          // pass
        }

      });
    },
    armrestAccessoryTextureHandler(armrestAccessory,side){
      armrestAccessory.addTexture = true
      var myMaterial = new BABYLON.StandardMaterial( armrestAccessory.name , this.scene);
      myMaterial.specularColor = new BABYLON.Color3(0.1, 0.1, 0.1);
      myMaterial.diffuseTexture   = new BABYLON.Texture("../assets/Textures/quattro_12-textures/quattro_accessory_newspaper-holder/newspaper-holder-400-"+side+"/quattro_newspaper-holder-400_newspaper-holder-400-"+side+"_aniline-cognac.jpg", this.scene, false, false);
      armrestAccessory.material = myMaterial

    },
    armrestTextureHandler(armrest){

      armrest.getChildren().forEach(element => {
          if( element.getClassName().includes('Mesh') ){
            var myMaterial = new BABYLON.StandardMaterial( armrest.name , this.scene);
            myMaterial.specularColor = new BABYLON.Color3(0.1, 0.1, 0.1);
            element.material = myMaterial
          }
      });


    },
    async importModel(path, modelName){
      // import from file ( gltf format ) only the root of the model for the first time

      // const importedModel = await BABYLON.SceneLoader.ImportMeshAsync(null, "../assets/models/", modelName+".glb", this.scene )
      const importedModel = await BABYLON.SceneLoader.ImportMeshAsync(null, "../assets/"+path, modelName+".glb", this.scene )
      

      
      // save only root
      var meshes = importedModel.meshes
      var rootNodes = meshes.filter((node) => { 
        return node.name == "__root__"
      })[0]
      rootNodes.name = modelName
      rootNodes.type = 'templateNode'

      rootNodes.getChildren().forEach(element => {
        element.freezeWorldMatrix();
        element.doNotSyncBoundingInfo = true;
        
        if( element.material ){
          element.material.dispose()
          element.addTexture = true
        }
      });

      this.changeTexturesForNode(  rootNodes )
      rootNodes.setEnabled(false)

      return rootNodes

    },
    makeModelInstance(model){
      // instance
      this.nodesInstanceCounter++
      var parentOfInstance = new BABYLON.TransformNode( "instance_"+this.nodesInstanceCounter+"_"+model.name )
      parentOfInstance.instanceId = this.nodesInstanceCounter
      parentOfInstance.rotationValue = 0
      parentOfInstance.modelName = model.name

      model.getChildren().forEach(child => {
          if( child.getClassName() == 'Mesh' ){
            // make instance if mesh
            var instance  = child.createInstance( child.name )
          }else{
            // copy transformnode else
            instance = new BABYLON.TransformNode( child.name )
          }
          instance.position = child.position
          instance.parent = parentOfInstance
          
      });

      return parentOfInstance
    },
    async addModel(path,modelName,furnitureTypeSymbol=null){

      // import model if not imported yet
      let model = this.scene.getMeshByName( modelName )
      if( model == undefined ){
        model = await this.importModel(path, modelName)
      }

      if(!this.modelOnScene[furnitureTypeSymbol]){
        this.modelOnScene[furnitureTypeSymbol] = {
          quantity:1,
          furnitureTypeSymbol:furnitureTypeSymbol,
          accessory:false
        }
      }else{
        this.modelOnScene[furnitureTypeSymbol].quantity ++ 
      }

      // create formated instance
      var instance = this.makeModelInstance(model)
      instance.leftSideTakenBy = null;
      instance.rightSideTakenBy = null;
      instance.furnitureTypeSymbol = furnitureTypeSymbol
      // change pivot position
      if( this.pickedAddbutton ){
        if( this.pickedAddbutton.name == undefined ){
          instance.position = new BABYLON.Vector3.Zero()
        }else{
          // reposition of model
          this.changeInstancePivotPointWithRotation( instance )
          this.handleSideTakenByInstance(instance)
          instance.position = new BABYLON.Vector3(this.positionOfModelsMergePoint.x, 0, this.positionOfModelsMergePoint.z)
          var side = this.pickedAddbutton.name.includes('_RIGHT') ? "RIGHT" : "LEFT"
          this.deleteEndpartFromSide(side)
        }
      }
      // remove highlight
      this.disposeGUIButtonForPickedModelAndRemoveEgdes()
      this.$parent.changeUpholstery()
      this.instances.push( instance )

      

    },
    changeInstancePivotPointWithRotation( instance ){
      
      // get element side mesh
      var addElementMesh =  this.getSideElementMeshFromInstanceElemenName(instance, this.pickedAddbutton.name )
      var pivotAt = addElementMesh.position;
      const translation = instance.position.subtract(pivotAt)

      // add rotation value
      instance.rotationValue += this.highlightedModel.rotationValue + this.extraRotationValue
      if( addElementMesh.name.includes('!R') ){
        var Rvalue = parseInt( addElementMesh.name.split("!R")[1] )
        instance.rotationValue += Rvalue
      }

      var axis = BABYLON.Axis.Y;
      instance.rotateAround(pivotAt, axis, BABYLON.Tools.ToRadians( instance.rotationValue ) )
      instance.setPivotMatrix(BABYLON.Matrix.Translation(translation.x, 0, translation.z),false);
      

    },
    getSideElementMeshFromInstanceElemenName(instance,instanceElementSide){
      var side = instanceElementSide.includes("LEFT") ? "RIGHT" : "LEFT"
      var addElementMesh = instance._children.filter((element) =>{
        return element.name.includes('A_ELEMENT_'+side)
      })[0];
      return addElementMesh
    },
    handleSideTakenByInstance(instance){
      var side = this.pickedAddbutton.name.includes("LEFT") ? "LEFT" : "RIGHT"
      var otherside = this.pickedAddbutton.name.includes("LEFT") ? "RIGHT" : "LEFT"

      var element = this.getSideElementMeshFromInstanceElemenName(instance,side)
      element.takenBy = instance.instanceId


      if( side == "LEFT" ){
        this.highlightedModel.leftSideTakenBy = instance.instanceId
        instance.rightSideTakenBy = this.highlightedModel.instanceId
        this.pickedAddbutton.takenBy = this.highlightedModel.instanceId

      }
      if( side == "RIGHT" ){
        this.highlightedModel.rightSideTakenBy = instance.instanceId
        instance.leftSideTakenBy = this.highlightedModel.instanceId
        this.pickedAddbutton.takenBy = this.highlightedModel.instanceId
      }
      


    },
    setFreeTakenSideAfterElementDelete( instanceId ){
      this.scene.rootNodes.forEach( node => {
        if( node.instanceId ){
          if( node.leftSideTakenBy == instanceId ){
            node.leftSideTakenBy = null
          }
          if( node.rightSideTakenBy == instanceId ){
            node.rightSideTakenBy = null
          }
        }
      })
    },
    makeAccessoryInstance(model){
      var parentOfInstance = new BABYLON.TransformNode( model.name )
      parentOfInstance.rotationValue = 0
      parentOfInstance.modelName = model.name

      model.getChildren().forEach(child => {
          if( child.getClassName() == 'Mesh' ){
            // make instance if mesh
            var instance  = child.createInstance( child.name )
          }else{
            // copy transformnode else
            instance = new BABYLON.TransformNode( child.name )
          }
          instance.position = child.position
          instance.parent = parentOfInstance
      });

      return parentOfInstance
    },
    changeAccessoryTexture(accessory){
      var model = accessory.getChildren()[0]
      // make material
      model.material = new BABYLON.StandardMaterial( model.name  , this.scene);

      // assign texture
      var texturePath = this.$parent.searchElementTextureByName( model.name )
      var textureUrl = texturePath.split('assets/')[1]
      model.material.specularColor = new BABYLON.Color3(0.1, 0.1, 0.1);
      model.material.diffuseTexture = new BABYLON.Texture( './../../shared/assets/'+textureUrl, this.scene, false, false);
    },
    changeAllAccessoryTexture(){
      this.scene.rootNodes.forEach(element => {
        if(element.name.includes('ACCESSORY_')){
          this.changeAccessoryTexture(element)
        }
      });
    },
    async importAccessories(path , fileName, modelName, type){
      var modelNameSplitedAccessory = modelName.split('accessory_')[1]
      var importedModel = await BABYLON.SceneLoader.ImportMeshAsync( null, "../assets/"+path, fileName+".glb", this.scene )
      var meshes = importedModel.meshes
      
      var accessory = null
      meshes.forEach(mesh => {
        mesh.type = 'accessory'
        if( mesh.name == modelNameSplitedAccessory ){
          accessory = mesh.parent
          accessory.setParent(null)
        }else{
          mesh.setEnabled(false)
        }
      });

      var rootN = meshes.filter((node) => { 
        return node.name == "__root__"
      })[0]
      rootN.dispose()

      // change accessoty texture
      this.changeAccessoryTexture( accessory )

      accessory.name = 'ACCESSORY_'+type
      accessory.furnitureTypeSymbol = type
      accessory.setEnabled(false)

      return accessory
    },
    async addAccessory(path , fileName, modelName, type){
      // import model if not imported yet
      var accessory = this.scene.getTransformNodeByName( 'ACCESSORY_'+type )
      if( accessory == undefined ){
        accessory = await this.importAccessories(path , fileName, modelName, type)
      }

      // ENDPART
      if( modelName.includes('endpart') ){
        var endpartValid = await this.addEndpartToSide(type)
        if(!endpartValid){
          return 0
        }
      }
      var accessoryInstance = this.makeAccessoryInstance( accessory ) 
      accessoryInstance.scaling = new BABYLON.Vector3( .001, .001, .001)
      accessoryInstance.parent = this.pickedAddAccbutton
      accessoryInstance.furnitureTypeSymbol = type
      this.pickedAddAccbutton.takenBy = accessoryInstance

      this.disposeGUIButtonForPickedModelAndRemoveEgdes()

      if(!this.modelOnScene[type]){
        this.modelOnScene[type] = {
          quantity:1,
          furnitureTypeSymbol:type,
          accessory:true
        }
      }else{
        this.modelOnScene[type].quantity ++ 
      }
      // reposition endpart
      if( this.pickedAddAccbutton.endpartPosition ){
        this.endparts[this.pickedAddAccbutton.endpartSide] = accessoryInstance
        accessoryInstance.position = this.pickedAddAccbutton.endpartPosition
      }

      if( type == '1950' ){
        accessoryInstance.scaling = new BABYLON.Vector3(-.001, .001, .001)
      }
    },
    async importArmrests(path , fileName, type){
      // import armrest
      var elements = ['D_ARMREST_'+type,'D_ARMREST_'+type+'_R', 'D_LEG-SET_main' ]
      var importedModel = await BABYLON.SceneLoader.ImportMeshAsync( elements, "../assets/"+path, fileName+".glb", this.scene )
      var meshes = importedModel.meshes

      meshes.forEach(mesh => {
        mesh.type = 'armrest'
      });

      var rootNodes = meshes.filter((node) => { 
        return node.name == "__root__"
      })[0]
      rootNodes.name = 'ARMRESTS'

      this.armrests['LEFT'] = rootNodes._children[0]
      this.armrests['LEFT'].position = new BABYLON.Vector3(0,0,0)

      this.armrests['RIGHT'] = rootNodes._children[1]
      this.armrests['RIGHT'].position = new BABYLON.Vector3(0,0,0)
      
      // Fecht accessory and legs node
      try{
        var armrestAccessory = rootNodes._children.filter((node) => { 
          return node.name.includes('ACCESSORY')
        })[0]
        armrestAccessory.setEnabled( false )
      }catch(e){
        // pass
      }

      
      var DLegSet = rootNodes._children.filter((node) => { 
        return node.name.includes('D_LEG')
      })[0]

      DLegSet.scaling = new BABYLON.Vector3(1, 1, 1)
      DLegSet.parent = null

      var items = [ armrestAccessory , DLegSet ]
      var armrests = [this.armrests['RIGHT'] , this.armrests['LEFT'] ]

      // for each armrests parent a accesory and legs anchors , to its positions. With all children nodes inside  
      armrests.forEach(armrest => {
        items.forEach(item => {
          try{
            var clone = item.clone( item.name )
            clone.parent = armrest
          }catch(e){
            // pass
          }

        });
        // this.repositionArmrestsAccessory(armrest)

        // get all legs offset
        var legPlaces = armrest.getChildren().filter((element) =>{
          return element.name.includes('A_LEG')
        })

        // for each offset make pack of anchor
        legPlaces.forEach(lp => {
          var legsAnch = DLegSet.clone(DLegSet.name)
          legsAnch.parent = lp
        });
      });
      this.armrestslegsSet = DLegSet

      this.armrestTextureHandler( this.armrests['RIGHT'] )
      this.armrestTextureHandler( this.armrests['LEFT'] )

      this.$parent.changeArmrestUpholstery()
      
    },
    async replaceArmrest(path , modelName, type){
      this.scene.transformNodes.forEach(element => {
        if(   element.name.includes('ARMREST') ||
              element.name.includes('D_LEG-SET')
        ){
          element.dispose()
        }
      });
      this.scene.meshes.forEach(element => {
        if(element.name.includes('ARMREST') ){
          element.dispose()
        }
      });
      await this.importArmrests(path , modelName, type)
      await this.armrestLegsHandler(this.legModel, '100' , null )
      this.checkArmrestsPosition()
    },
    checkArmrestsPosition(){
      this.scene.rootNodes.forEach( node => {
        if( node.instanceId ){
          this.repositionArmrests(node)
          if(this.legsMehses){
            this.setLegsToModel(node)
          }
        }
      })
    },
    repositionArmrests(node, attempt=0){
      try{
        if( node.leftSideTakenBy == null ){
          var element = node.getChildren().filter( child => {
            return child.name.includes('A_ELEMENT_LEFT')
          })[0]
          // try delete dummyForArmrest
          try {
            this.scene.getMeshByName('dummyForArmrestL').dispose()
          } catch (error) {
            // pass
          }
          var dummy = this.dummyBox.createInstance('dummyForArmrestL')
          dummy.position = element.position.clone()
          dummy.isVisible = false
          this.armrests['LEFT'].parent = node
          this.armrests['LEFT'].position = dummy.position
          var rotation = 0
          if( element.name.includes('!R') ){
            var Rvalue = parseInt( element.name.split("!R")[1] )
            rotation += Rvalue *-1
            var axis = BABYLON.Axis.Y;
            this.armrests['LEFT'].rotateAround( dummy.position , axis, BABYLON.Tools.ToRadians( rotation ) )
          }
        }
        if( node.rightSideTakenBy == null ){
          this.armrests['RIGHT'].rotation = new BABYLON.Vector3.Zero()
          var element = node.getChildren().filter( child => {
            return child.name.includes('A_ELEMENT_RIGHT')
          })[0]
          // try delete dummyForArmrest
          try {
            this.scene.getMeshByName('dummyForArmrest').dispose()
          } catch (error) {
            // pass
          }
          dummy = this.dummyBox.createInstance('dummyForArmrest')
          dummy.position = element.position.clone()
          dummy.isVisible = false
          this.armrests['RIGHT'].parent = node
          this.armrests['RIGHT'].position = dummy.position
          var rotation = 0
          if( element.name.includes('!R') ){
            var Rvalue = parseInt( element.name.split("!R")[1] )
            rotation += Rvalue *-1
            var axis = BABYLON.Axis.Y;
            this.armrests['RIGHT'].rotateAround( dummy.position , axis, BABYLON.Tools.ToRadians( rotation ) )
          }
        }
      }catch(e){
        setTimeout(() => {
          if( attempt < 3 ){
            this.repositionArmrests(node, attempt+1)
          }
        }, 1000 );
      }
      this.hideArmrests()
    },
    repositionArmrestsAccessory( armrest ){

      // Fecht accessory
      var side = armrest.name.split('0_')[1] != undefined ? armrest.name.split('0_')[1] : 'L'
      var armrestAccessory = armrest._children.filter((node) => { 
        return node.name.includes('D_ACCESSORY')
      })[0].getChildren()[0]

      var armrestAccessoryAnchor = armrest._children.filter((node) => { 
        return node.name.includes('A_ACCESSORY')
      })[0]
      armrestAccessory.setEnabled(true)
      armrestAccessory.parent = armrestAccessoryAnchor

      armrestAccessoryAnchor.rotation = new BABYLON.Vector3.Zero()

      // accessory texture
      this.armrestAccessoryTextureHandler( armrestAccessory, side )

    },
    hideArmrests(){
      try{
        if( this.armrests['RIGHT'] ){
          var element = this.$parent.searchElementByFurnitureType(this.armrests['RIGHT'].parent.furnitureTypeSymbol)
          if( element.hasLeftArmrest == false ){
            this.armrests['RIGHT'].setEnabled(false)
          }else{
            this.armrests['RIGHT'].setEnabled(true)
          }
        }
        if( this.armrests['LEFT'] ){
          var element = this.$parent.searchElementByFurnitureType(this.armrests['LEFT'].parent.furnitureTypeSymbol)
          if( element.hasRightArmrest == false ){
            this.armrests['LEFT'].setEnabled(false)
          }else{
            this.armrests['LEFT'].setEnabled(true)
          }
        }
      }catch(e){
        setTimeout(() => {
          this.hideArmrests()
        }, 100);
      }

      
    },
    async addEndpartToSide(typeSymbol){
      var element = this.$parent.searchAccessoryByFurnitureType(typeSymbol)
      var side  = element.furnitureTypeName.includes(' L') ? "LEFT" : "RIGHT"
      
      await this.deleteEndpartFromSide(side)

      if(!this.armrests[ side ].isEnabled() ){
        this.pickedAddAccbutton = this.armrests[ side ].parent
        this.pickedAddAccbutton.endpartPosition = this.armrests[ side ].position.clone()
        this.pickedAddAccbutton.endpartSide = side
      }else{
        alert("Nie można dodać elementu, ponieważ miejsce jest zajęte!")
        return false
      }
      return true
    },
    deleteEndpartFromSide(side){
      try{
        this.endparts[side].dispose()
      }catch(e){
        // pass
      }
    },
    async importLegs( fileName ){
      if( this.scene.getMeshByName( 'LEGS' ) ){
        var rootNodes = this.scene.getMeshByName( 'LEGS' )
      }else{
        var importedModel = await BABYLON.SceneLoader.ImportMeshAsync( null, "../assets/families/", fileName+".glb", this.scene )
        var meshes = importedModel.meshes
        this.legsMehses = meshes
        rootNodes = meshes.filter((node) => { 
          return node.name == "__root__"
        })[0]
        rootNodes.name = 'LEGS'
      }
      rootNodes.getChildren().forEach(element => {
        element.setEnabled(false)
      });

      this.legsNodes = rootNodes

      // picked Legs mock
      this.armrestLegsHandler(this.legModel, '100' , null )

    },
    replaceLegs(){
      this.legInstancesContainer.forEach(element => {
          element.dispose()
      });
      this.checkArmrestsPosition()
      this.armrestLegsHandler(this.legModel, '100' , null )
    },
    armrestLegsHandler( type, height, color ){
      // position of leg in armrests

      var armrests = [ this.armrests['RIGHT'] , this.armrests['LEFT'] ]
      armrests.forEach(armrest => {
        armrest.getChildren().forEach(element => {
            if(element.name.includes('A_LEG')){
              element.rotation = new BABYLON.Vector3.Zero()
              element.getChildren().forEach(legAnchor => {
                  legAnchor.rotation = new BABYLON.Vector3.Zero()
                  legAnchor.getChildren().forEach( anchorType => {
                      if( anchorType.name == 'D_LEG-SET_main.A_LEG_'+type ){
                        anchorType.scaling = new BABYLON.Vector3(1000, 1000, 1000)
                        this.attachLegToParent(anchorType)
                        if( element.name.includes('FB') && !this.notRotebleLegs.includes(this.legModel) ){
                          anchorType.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(270), 0)
                        }
                      }
                  });
              })
            }
        })
      });

    },
    setLegsToModel(model){
      
      model.getChildren().forEach(element => {
        if( element.name.includes('A_LEG-SET') || element.name.includes('A_LEG_'+this.legModel+'_')  ){
          this.attachLegToParent(element)
        }  
      });

    },
    attachLegToParent(parent){
      if(parent.name.includes('LEFT') || parent.parent.rightSideTakenBy === null ){
        return
      }
      
      parent.rotation = new BABYLON.Vector3.Zero()

      if( !this.notRotebleLegs.includes(this.legModel) || parent.parent.name.includes('corner') ){
          if( parent.name.includes('FB') && parent.name.includes('LR') ){
            if(parent.parent.name.includes('corner') && this.notRotebleLegs.includes(this.legModel)  ){
              parent.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(45*-1), 0)
            }else{
              parent.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(180), 0)
            }
            
          }
          else if( parent.name.includes('FB') ){
            parent.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(270), 0)
          }
          else if( parent.name.includes('LR') ){
            parent.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(90), 0)
          }
      }

      if( parent.name.includes('!R') ){
        var Rvalue = parseInt( parent.name.split("!R")[1] )
        parent.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(Rvalue*-1), 0)
      }


      this.legOriginalNode = this.legsMehses.filter(l=>{
        return l.name.includes(this.legModel)
      })[0]

      var leginstance = this.legOriginalNode.createInstance('L')
      leginstance.setEnabled(true)

      leginstance.position = new BABYLON.Vector3.Zero()
      leginstance.parent = parent
      leginstance.scaling = new BABYLON.Vector3(0.001, 0.001, 0.001)
      this.legInstancesContainer.push(leginstance)

      return leginstance

    },
    changeLegColor( textureUrl ){
      var myMaterial = new BABYLON.StandardMaterial( this.legOriginalNode.name , this.scene);
      myMaterial.specularColor = new BABYLON.Color3(0.1, 0.1, 0.1);
      myMaterial.diffuseTexture = new BABYLON.Texture( './../../shared/assets/'+textureUrl, this.scene, false, false);
      this.legOriginalNode.material = myMaterial
    },
    hideModelLegWithArmrest(){
      try{
        var node = this.armrests['RIGHT'].parent
        node.getChildren().forEach(element => {
          if( element.name.includes('A_LEG') ){
            var leg = element.getChildren()
            try{
              leg[0].dispose()
            }catch(e){
              // pass
            }
          }
        })
      }catch(e){
        setTimeout(() => {
          this.hideModelLegWithArmrest()
        }, 200);
      }

    },
    unsetParent(){
      this.armrests['RIGHT'].parent = null
      this.armrests['LEFT'].parent = null

      this.armrests['RIGHT'].rotation = new BABYLON.Vector3.Zero()
      this.armrests['LEFT'].rotation = new BABYLON.Vector3.Zero()
    },
    checkSceneRootNodes(){
      this.instancesIds = []
      this.scene.rootNodes.forEach( node => {
        if( node.instanceId ){
          this.instancesIds.push(node.instanceId)
          try{
            this.hideModelLegWithArmrest()
          }catch(e){
            // pass
          }
        }
      })
      if( ( this.numberOfAllInstances != this.instancesIds.length )){
          this.numberOfAllInstances = this.instancesIds.length
          this.checkArmrestsPosition()
      }
    },
    midpoint(x1, z1, x2, z2) {
      return [(x1 + x2) / 2, (z1 + z2) / 2];
    },
    angle(cx, cy, ex, ey) {
      var dy = ey - cy;
      var dx = ex - cx;
      var theta = Math.atan2(dy, dx); // range (-PI, PI]
      theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
      //if (theta < 0) theta = 360 + theta; // range [0, 360)
      return theta;
    },
    repositionCamera(){
      try {
        var x1 = this.armrests['LEFT'].parent.absolutePosition.x 
        var z1 = this.armrests['LEFT'].parent.absolutePosition.z 
        var x2 = this.armrests['RIGHT'].parent.absolutePosition.x 
        var z2 = this.armrests['RIGHT'].parent.absolutePosition.z

        var midPoint = this.midpoint(x1,z1,x2,z2)
        var angle = this.angle(x1,z1,x2,z2)
        
        var animationcamera = new BABYLON.Animation(
            "myAnimationcamera",
            "position", 
            30, 
            BABYLON.Animation.ANIMATIONTYPE_VECTOR3, 
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        var keys = []
        var position = this.boxTargetCamera.position.clone()
        var centerPosition = new BABYLON.Vector3( midPoint[0],0,midPoint[1] )

        keys.push({
          frame: 0,
          value: position,
        });        
        keys.push({
          frame: 10,
          value: centerPosition
        });

        animationcamera.setKeys(keys);

        var animationCameraRotation = new BABYLON.Animation(
            "animationCameraRotation",
            "rotation", 
            30, 
            BABYLON.Animation.ANIMATIONTYPE_VECTOR3, 
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );
        var keys1 = []
        keys1.push({
          frame: 0,
          value: this.boxTargetCamera.rotation,
          });        
        keys1.push({
          frame: 10,
          value: new BABYLON.Vector3( 0, BABYLON.Tools.ToRadians( angle*-1 ), 0 )
        });

        animationCameraRotation.setKeys(keys1);
        this.boxTargetCamera.animations = [];
        this.boxTargetCamera.animations.push(animationcamera);
        this.boxTargetCamera.animations.push(animationCameraRotation);
        this.scene.beginAnimation(this.boxTargetCamera, 0, 100, false, 1 );

        this.camera.beta = 1.5 - this.numberOfAllInstances/20
        this.camera.radius = 8 + this.numberOfAllInstances*2


      } catch (error) {
        console.log( 'ERROR', error )
      }



    },

  },
  watch:{
    'armrestPositionX':{
        handler: function (after, before) {
            if( after == 0 ){
              this.checkArmrestsPosition()
            }
        },
        deep: true
    },
    'scene.rootNodes.length': {
        handler: function (after, before) {
            this.checkSceneRootNodes()
        },
        deep: true
    },
    'numberOfAllInstances':{
        handler: function (after, before){
            setTimeout(() => {
              this.repositionCamera()
            }, 10 );
        },
        deep:true
    },
  }
  
}
</script>

<style >

#renderCanvas2{
    width: 100%;
    height: 90%;
    touch-action: none;
    outline: none;
}

</style>
