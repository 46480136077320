
<template>
  <div v-if="(sceneIsBussy || jsonLoaderDisplayStyle == 'block') && $parent.startFromAngular" class="loader-container" >
    <div class="loader-img">
      <!-- <div class="loader"> </div> -->
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 250 250" enable-background="new 0 0 250 250" xml:space="preserve">

    <path 
      :class="{'path-load-json': (!loadFromJsonStatus && $parent.selectionFromJson) , 'path-load': (loadFromJsonStatus || !$parent.selectionFromJson) }"
      :style="loaderStyle"

      d="M159.9,222.6c21.9,0,65.4-3.5,65.4-49.2c0-41.8-18.1-72.4-68-72.4H90.8c-12.5,0-16.4-3.1-16.4-8.7c0-8.3,5.5-15.9,19-15.9
      h57.5c4.1,0,7.6-3.3,7.6-7.4V35.2c0-3.9-3.3-7.2-7.2-7.4H93.4c-47.6,0-68,27.6-68,72.4c0,10,0,49.4,65.4,49.4h66.5
      c15.3,0,19,8.7,19,15.7c0,5.3-3.7,8.7-14.7,8.7H40.1c-4.1,0-7.4,3.3-7.4,7.4v33.9c0,4.1,3.3,7.4,7.4,7.4L159.9,222.6L159.9,222.6z"
      />

    </svg>
    </div>
  </div>
  <div id="renderCanvas" >
    <div id="json-loader-background" :style="{'display':jsonLoaderDisplayStyle}" ></div>
    <canvas id="renderCanvasScene" touch-action="none"  ></canvas>
  </div>

</template>


<script>
import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import 'babylonjs-loaders';

export default {
  name: 'MyScene',
  components: {
  },
  data(){
    return{
      canvas:null,
      engine:null,
      scene:null,
      actionManager:null,
      camera:null,
      
      sceneIsBussy: false,
      loadDefaultModel:true,
      allTexturesIsReady:false,
      loadFromJsonStatus: false,

      legsImportInProgress:false,

      nodesInstanceCounter:0,
      numberOfAllInstances:0,

      instanceOnTheScene : [],
      baseInstances: [],

      legModel:{},
      armrests:{},
      armrestMaterial:{},
      modelOnScene:{},
      pickedAddbutton:{},
      highlightedModel:{},
      highlightedModelCloned:{},
      GUIAssetsButton:{},
      D_legSet:{},

      notRotebleLegs : [],
      mainLegModel:null,
      mainLegModelSymbol:null,
      secLegModel:null,
      secLegModelSymbol:null,

      noSpecular : new BABYLON.Color3(0, 0, 0),
      leatherSpecular : new BABYLON.Color3( .15, .15, .15 ),

      posBox:[]

    }
  },
  created(){
  },
  mounted(){
    this.initializeEngine()
    this.createboxesTargetCamera()
    // Returns a Promise that resolves after "ms" Milliseconds
    this.timer = ms => new Promise(res => setTimeout(res, ms))
  },
  computed:{
    extraRotationValue(){
      var rotation = 0
      try{
        if( this.pickedAddbutton.parent.name.includes('!R') ){
          var Rvalue = parseInt( this.pickedAddbutton.parent.name.split("!R")[1] )
          rotation = Rvalue *-1
        }
      }catch(e){
        // pass
      }

      return rotation
    },
    sideToExtend(){
      var addBtn = null

      try{
        // console.log( 'SIDE TO ETX' , this.pickedAddbutton.parent['name'] )
        if( this.pickedAddbutton.parent['name'].includes('A_ACCESSORY') ){
          addBtn = this.pickedAddbutton.parent['name']
        }else{
          addBtn = this.pickedAddbutton.parent['name'].split('_')[ this.pickedAddbutton.parent['name'].split('_').length-1 ]
        }
      }catch(e){  
        addBtn = null
      }

      return addBtn
    },
    jsonLoaderDisplayStyle(){
      var loaderStyle = 'block'
      if( this.loadFromJsonStatus || !this.$parent.selectionFromJson ){
        loaderStyle = 'none'
      }

      return loaderStyle
    },
    jsonLoaderProgress(){
      var maxValue = 1200
      var orderAmount = this.$parent.orderAmount
      var orderAmountUpdatedPrecent = this.$parent.orderAmountUpdated * 100 / orderAmount
      var orderAmountUpdatedValue = orderAmountUpdatedPrecent * (maxValue/100)
      return maxValue - orderAmountUpdatedValue 

    },
    loaderStyle(){

      var style = {}

      if(!this.loadFromJsonStatus && this.$parent.selectionFromJson ){
        style = {
          'stroke-dasharray':1200,
          'stroke-dashoffset':this.jsonLoaderProgress
        }
      }else{
        style = {
          'stroke-dasharray':1200,
          'stroke-dashoffset':1250,
        }
      }

      return style

    }
  },
  methods:{
    initializeEngine(){
      /* Initialize scene engine  */

      // Get the canvas DOM element
      this.canvas = document.getElementById('renderCanvasScene');
      
      // Load the 3D engine
      var engine = new BABYLON.Engine(this.canvas, true, {doNotHandleContextLost: true , stencil: true} );
      this.engine = engine
      this.engine.enableOfflineSupport = false;
      this.engine.doNotHandleContextLost = true;
      this.engine.getCaps().parallelShaderCompile = undefined;

      // call the createScene function
      var scene = this.createScene();
      
      // make hightlightner
      this.highlightner = new BABYLON.HighlightLayer("hl1", scene);

      // run the render loop
      this.engine.runRenderLoop(function(){
          scene.render();
      });

      // the canvas/window resize event handler
      window.addEventListener('resize', function(){
          engine.resize();
      });
      


      this.scene = scene

    },
    createScene(){
      // Create a BJS Scene object

      var scene = new BABYLON.Scene(this.engine);
      
      // gui button 
      this.createGUIButton()

      // create Camera
      this.createNewCamera()

      // Create a basic light, aiming 0, 1, 0 - meaning, to the sky
      var light  = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 1, 0), scene);
      light.intensity = 1.3;

      // change scene system
      scene.useRightHandedSystem = true

      // Optimalization
      scene.autoClear = false; // Color buffer
      scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously
      scene.blockMaterialDirtyMechanism = true;

      // change screen color to transparent
      // scene.clearColor  = new BABYLON.Color4(0, 0, 0, 0);

      // optimalization
      scene.getAnimationRatio();

      // actionManager
      this.actionManager = new BABYLON.ActionManager(scene);

      // on mesh click
      var onMeshClick = this.onMeshClick
      var hideGUIButtonForPickedModel = this.hideGUIButtonForPickedModel
      var setModelToHighlight = this.setModelToHighlight
      scene.onPointerDown = function (evt, pickResult) {
        if(evt.button  == 2 ){
          // right click
          // free rotate camera
        }else if( evt.button == 0 && pickResult.pickedMesh != undefined ){
          onMeshClick( pickResult.pickedMesh )
        }else if( evt.button == 0 ){
          hideGUIButtonForPickedModel()
          setModelToHighlight({})
        }
      }

      // make dumb mesh to prevent empty scene frezze 
      BABYLON.MeshBuilder.CreateBox("freezeScenePrevent", {height: .001, width: .001, depth: .001});

      scene.collisionsEnabled = true;
      

      // Return the created scene
      return scene;
    },
    async checkSceneIsReady(){
      // console.log( 'SCENE IS READY' )
      await this.changeLegModel()
      this.engine.resize();

      // TEMP
      // var legs = this.scene.meshes
      // // console.log('rereerredds', legs ) 

      this.scene.whenReadyAsync().then(() => this.sceneIsBussy = false );
      
      
    },
    toogleFreezeActiveMeshes( freeze ){
      if(freeze){
        this.scene.freezeActiveMeshes();
        this.resetHithlight()
      }else{
        this.scene.unfreezeActiveMeshes();
      }
    },
    createNewCamera(){
      try{
        this.camera.dispose()
      }catch(e){

      }
      
      // Parameters: name, alpha, beta, radius, target position, scene
      this.camera = new BABYLON.ArcRotateCamera("Camera", 0, 0, 1, new BABYLON.Vector3(0, .4, 0), this.scene);
      
      // This positions the camera
      this.camera.setPosition(new BABYLON.Vector3(0, 3, 35));

      // Change sensivity of mouse whell zoom
      this.camera.wheelPrecision = 20
      this.camera.fov = .05
      
      // Attach the camera to the canvas
      this.camera.attachControl(this.canvas, true);
      
      this.camera.checkCollisions = true;
      this.camera.collisionRadius = new BABYLON.Vector3(5, 5, 5);


    },
    createboxesTargetCamera(){
      this.boxTargetCamera = BABYLON.MeshBuilder.CreateBox("boxTargetCamera", {height: .2, width: .2, depth: .2});
      this.boxTargetCamera.isVisible = false

    },
    deleteAllSceneModels(){
      /* Delete all imported model from scene */

      this.loadDefaultModel = true
      // models
      this.scene.rootNodes.forEach(element => {
          if( !element.name ){
            // pass
          }
          else if( (element.templateType || element.name.includes('instance_')) && element.name != 'LEGS' ){
            element.dispose()
          }
      });
      this.instanceOnTheScene.forEach(element => {
          element.deleteItem = true
          element.dispose()
      });

      this.scene.materials.forEach(mat => {
        if(!mat.name.includes('Advanced')){
          mat.dispose()
        }
      });

      this.createNewCamera()

      return 0
    },
    deleteAllSceneMeshes(){
      /* Delete all imported model from scene */

      this.loadDefaultModel = true

      // models
      this.scene.rootNodes.forEach(element => {
          if( !element.name ){
            element.dispose()
          }
          else if( element.templateType || element.name.includes('instance_') ){
            element.dispose()
          }
      });
      this.scene.transformNodes.forEach(element => {
          if( !element.name ){
            element.dispose()
          }
          else if( element.name.includes('instance_') || element.templateType == "ACCESSORY" ){
            if( element.furnitureTypeSymbol ){
              element.deleteItem = true
              // window.dispatchEvent(new CustomEvent('change-quantity-from-3d', {detail: element} ))
            }
            element.dispose()
          }
      });

      this.scene.materials.forEach(mat => {
        if(!mat.name.includes('Advanced')){
          mat.dispose()
        }
      });

      this.createNewCamera()

      return 0
    },

    // // T E X T U R E // // 
    changeMeshTexture(meshObject, textureFullPath){
      /* Change texture of mesh object */
      var textureName = textureFullPath
      if( !meshObject.material ){

        meshObject.material = new BABYLON.StandardMaterial( meshObject.name  , this.scene);

        if( this.$parent.selectedFamily['coverTypeSymbol'] == '2' ){
          meshObject.material.specularColor = this.noSpecular
        }else{
          meshObject.material.specularColor = this.leatherSpecular
        }
      }
      
      // // console.log( 'meshObjectmeshObject', meshObject.name , textureFullPath )
      
      // check if texture was added before
      var textureFromCache = this.scene.textures.filter(texture=>{
        return texture.name == textureName
      })[0]

      // load from cache if possible
      if( textureFromCache ){
        // console.log('LEG MESH TEXTURE a cache', meshObject.name, textureFullPath)
        meshObject.material.diffuseTexture = textureFromCache        
      }else{
        // create new one
        // console.log('LEG MESH TEXTURE a', meshObject.name, textureFullPath)
        meshObject.material.diffuseTexture = new BABYLON.Texture( textureFullPath, this.scene, false, false);
      }

    },
    changeModelMeshTexture(model){
      /* Change all model mesh texture */
      model.getChildren().forEach(child => {
        try {
          if( child.addTexture ){
            
            var texturePath =  this.$parent.getTextureByMeshName( model.originalModelName.toLowerCase() , child.name.toLowerCase() )
            

            if( texturePath ){
              this.changeMeshTexture(child, texturePath)
            }else{
              
            }
          }
        } catch (error) {
          // console.log( 'meshObjectmeshObject', child.name , texturePath )
          console.warn('TEXTURE ERROR' , error )
        }

      });
      
    },
    changeAllTextures(){
      this.sceneIsBussy = true
      this.scene.rootNodes.forEach(element => {
        if( element.templateType == 'ELEMENT' || element.templateType == 'ACCESSORY'){
          this.changeModelMeshTexture(element)
        }
      });

      this.armrestTextureHandler( this.armrests['RIGHT'], 'r' )
      this.armrestTextureHandler( this.armrests['LEFT'] , 'l' )

      // this.scene.whenReadyAsync().then(() => this.checkSceneIsReady() );
      this.$parent.refreshPrice()


    },
    armrestTextureHandler(armrest, side){
      if( !armrest  ){
        return;
      }

      armrest.getChildren().forEach(element => {
          if( element.getClassName().includes('Mesh') ){
            var sideMat = side == 'r' ? "RIGHT" : "LEFT"
            var textureUrlOrig = this.$parent.getTextureForArmrest( side )

            if( !textureUrlOrig ){
              return false
            }

            textureUrlOrig = textureUrlOrig['textureFullPath']
            if( textureUrlOrig.startsWith( "/assets" ) ){
                textureUrlOrig = '/shared' + textureUrlOrig
            }
            var textureUrl = textureUrlOrig.split('assets/')[1]
            var textureName = './../../shared/assets/'+textureUrl
            if( !this.armrestMaterial[sideMat] ){
              this.armrestMaterial[sideMat] = new BABYLON.StandardMaterial( 'armrest_'+side , this.scene);
              this.armrestMaterial[sideMat].specularColor = this.noSpecular
            }

            // check if texture was added before
            var textureFromCache = this.scene.textures.filter(texture=>{
              return texture.name == textureName
            })[0]
            // load from cache if possible
            if( textureFromCache ){
              this.armrestMaterial[sideMat].diffuseTexture = textureFromCache
            }else{
              // create new one
              // console.log( 'textureUrlOrigtextureUrlOrig', textureUrlOrig )
              this.armrestMaterial[sideMat].diffuseTexture = new BABYLON.Texture( textureUrlOrig, this.scene, false, false);
            }

            // pick mesh from armrest parent
            var armrestMesh = armrest.getChildren().filter(child => {
              return child.getClassName().includes('Mesh')
            })[0]
            armrestMesh.material = this.armrestMaterial[sideMat]
          }
      });

    },
    allTexturesIsReadyCallback(){
      this.sceneIsBussy = false
    },
    chnageLegTextures(legMesh, textureFullPath){

      try{

        // console.log('LEG MESH TEXTURE ad', legMesh.name, textureFullPath)

        legMesh.material = new BABYLON.StandardMaterial( 'leg_material_'+legMesh.name , this.scene);        
        this.changeMeshTexture( legMesh , textureFullPath )
      }catch(e){
        // console.log('LEG MESH TEXTURE ERROR ', e)
        // pass
      }

    },

    // // H I G H L I G H T // // 
    createGUIButton(){
      /* Create GUI Button for model when picked  */

      var buttons = [ 'add_black', 'add_green', 'remove_black', 'remove_red' ] 
      
      buttons.forEach(btn => {
        var plane = BABYLON.MeshBuilder.CreateDisc(btn, {radius: 6, sideOrientation: BABYLON.Mesh.DOUBLESIDE } );
        plane.rotation.x = BABYLON.Tools.ToRadians( 180 )
        plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
        var advancedTexture = new GUI.AdvancedDynamicTexture.CreateForMesh(plane);
        var button = GUI.Button.CreateImageOnlyButton(btn , "/vue-app/gui/icon-"+btn+".png");
        button.occlusionType = BABYLON.AbstractMesh.OCCLUSION_TYPE_STRICT;
        advancedTexture.addControl(button);
        plane.isVisible = false
        this.GUIAssetsButton[btn] = plane
      });

    },
    createCloneOfGUIBtn(btnName){
      var buttonInstance = this.GUIAssetsButton[btnName].clone( 'BTN' )
      this.highlightner.addMesh(buttonInstance, BABYLON.Color3.White() );
      return buttonInstance
    },
    onMeshClick(model){
      /* Allow to click only at ['Elements' , 'Accessories'] screen name */

      var elementsAddScreenName = ['Elements' , 'Accessories']

      if( !elementsAddScreenName.includes( this.$parent.selectedData.title ) || model.name.includes('colider') ){
        return 0
      }

      // get the parent of clicked mesh
      var parent = model.parent

      // if delete btn is clicked
      if ( model.name == 'DELETE_BTN'){
        this.removeInstanceFromTheScene(parent)
      }

      // if model is clicked again remove highlight 
      if( this.highlightedModel.name == parent.name ){
        this.hideGUIButtonForPickedModel()
        this.setModelToHighlight({})
        return 0
      }else if ( parent.templateType )  {
        this.hideGUIButtonForPickedModel()
        this.setModelToHighlight( parent )
      }
      
      // check if button is clicked
      if( model.name.includes('BTN') ){
        this.handleButtonClicked(model)
      }else if(parent.clickable){
        // assign model to highlight
        this.setModelToHighlight(parent)
        // show buttons
        this.showGUIButtonForPickedModel(parent)
        // highlight model
        this.highlightPickedModel()

      }

    },
    handleButtonClicked(button){
      /* handle clicked button */
      this.chnageButtonToPicked(button)
    },
    chnageButtonToPicked(button){
      /* Swith picked button */

      // console.log( 'PICKED BZTN' ,  button )
      if( this.pickedAddbutton ){
        this.pickedAddbutton.material = this.GUIAssetsButton['add_black'].material
      }
      button.material = this.GUIAssetsButton['add_green'].material
      this.pickedAddbutton = button

    },
    showGUIButtonForPickedModel(model){
      /* Show GUI button for delete and model elements connectors  */

      this.hideGUIButtonForPickedModel()
      
      model.getChildren().forEach(node => {
        // only TransformNode class
        if( node.getClassName() == 'TransformNode' && !node.name.includes('A_LEG') ){
          var newButton = this.createCloneOfGUIBtn('add_black')
          newButton.isVisible = true
          newButton.position.y += .1
          newButton.parent = node
          newButton.scaling = new BABYLON.Vector3( .01 , .01 , .01  )

          // move A_ELEMENT connector higher
          if( node.name.includes('A_ELEMENT') ){
            newButton.position.y += .3
            newButton.scaling = new BABYLON.Vector3( .015 , .015 , .015 )

            var extednSides = this.$parent.searchElementByFurnitureType(node.parent.furnitureTypeSymbol)
            if( node.name.includes('RIGHT') ){
              if( model.rightSideTakenBy || !extednSides['isRightExtend'] ){
                newButton.isVisible = false
              }else{
                newButton.isVisible = true
              } 
            }
            if( node.name.includes('LEFT')  ){
              if( model.leftSideTakenBy || !extednSides['isLeftExtend'] ){
                newButton.isVisible = false
              }else{
                newButton.isVisible = true
              }
            }

            // Hide if accessory mode
            if( this.$parent.selectedData.title == 'Accessories' ){
              newButton.isVisible = false
            }

          }else{
            // Hide if elements mode
            if( this.$parent.selectedData.title == 'Elements' ){
              newButton.isVisible = false
            }
          }

        }
      });

      // show delete btn only for edged
      if( ( model.leftSideTakenBy == null || model.rightSideTakenBy == null ) && this.numberOfAllInstances > 1 ){
        this.showGUIDeleteButtonForPickedModel(model)
      }
      // show delete btn for accesories
      if( model.templateType == "ACCESSORY" ){
        this.showGUIDeleteButtonForPickedModel(model)
      }

    },
    showGUIDeleteButtonForPickedModel(model){
      if( model.templateType == 'ELEMENT' && this.$parent.selectedData.title == 'Accessories'  ){
        return 0
      }

      var newButton = this.createCloneOfGUIBtn('remove_black')
      newButton.isVisible = true
      newButton.name = 'DELETE_BTN'
      newButton.position.y += 1
      newButton.parent = model
      newButton.scaling = new BABYLON.Vector3( .015 , .015 , .015  )
      newButton.actionManager = this.actionManager;

      // on hover, change btn background color to red
      var GUIAssetsButton = this.GUIAssetsButton
      newButton.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function(ev){
              newButton.material = GUIAssetsButton['remove_red'].material
      }));
      newButton.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger,
      function (AM_event) {
          newButton.material = GUIAssetsButton['remove_black'].material
      }));

      // accessories
      if( model.templateType == 'ACCESSORY' ){
         newButton.position.y -= .5
      }
    },
    hideGUIButtonForPickedModel(){
      /* Hide GUI button for delete and model elements connectors  */
      try{
        this.tryRemoveHiglight()
        this.pickedAddbutton = {}
        this.highlightedModel.getChildren().forEach(child => {
          if( child.getClassName() == 'TransformNode' && !child.name.includes('A_LEG')  ){
            // remove BTN from connectors
            child.getChildren().forEach(el => {
              if( el.name == 'BTN' ){
                el.dispose()
              }
            });
          }
          if( child.name.includes('BTN') ){
            child.dispose()
          }
        });
      }catch(e){
        // can remove higlight
      }
    },
    setModelToHighlight(model){
      this.highlightedModel = model
    },
    highlightPickedModel(){
      /* Highlight edged of picked model */
      this.tryRemoveHiglight()

      // different aproach to highlight, by clone origin model at same place , then higlight
      // hook original model to clone
      if( this.highlightedModel.templateType == 'ACCESSORY' ){
        this.highlightedModelCloned = this.scene.getTransformNodeByName( this.highlightedModel.modelName ).clone( this.highlightedModel.modelName+'_highlight' )
      }else{
        this.highlightedModelCloned = this.scene.getMeshByName( this.highlightedModel.modelName ).clone( this.highlightedModel.modelName+'_highlight' )
      }

      this.highlightedModelCloned.position = this.highlightedModel.absolutePosition.clone()
      this.highlightedModelCloned.rotation = this.highlightedModel.rotation
      this.highlightedModelCloned.rotateAround(this.highlightedModelCloned.position, BABYLON.Axis.Y, BABYLON.Tools.ToRadians( this.highlightedModel.rotationValue ) )
      this.highlightedModelCloned.setEnabled(true)
      this.highlightedModelCloned.getChildren().forEach(child => {
            child.setEnabled(true)
            child.alwaysSelectAsActiveMesh = true
            try{
              this.highlightner.addMesh(child, BABYLON.Color3.Green());
            }catch(e){
              // pass
            }
      });
      this.tooglePickedModelVisibility(false)

    },
    tooglePickedModelVisibility(visible){
      this.highlightedModel.getChildren().forEach(child => {
            if( child.getClassName().includes('Mesh') && child.name != 'DELETE_BTN' && !child.name.includes('colider') ){
              child.isVisible = visible
            }
      });

    },
    tryRemoveHiglight(){

      if( Object.keys(this.highlightedModelCloned).length == 0 ){
        return 0
      }
      
      try {
        this.tooglePickedModelVisibility(true)
        this.highlightedModelCloned.getChildren().forEach(child => {
          if( child.getClassName().includes('Mesh') ){
            this.highlightner.removeMesh(child);
          }
      });
      } catch (error) {
        // console.log('REMv error' , error , this.highlightedModelCloned )
      }
      // try remove old highlightedModelCloned
      try {
        this.highlightedModelCloned.setEnabled(false)
        this.highlightedModelCloned.dispose()
        this.highlightedModelCloned = {}
      } catch (error) {
        // console.log('REMv error' , error , this.highlightedModelCloned )

      }
    },
    resetHithlight(){
      this.tryRemoveHiglight()
      this.hideGUIButtonForPickedModel()
      this.setModelToHighlight({})
      this.loadDefaultModel = false
      this.pickedAddbutton = {}
    },

    // // M O D E L // //
    async importModel(path, modelName, furnitureTypeSymbol){
      /* import from file ( gltf format ) for the first time */
      const importedModel = await BABYLON.SceneLoader.ImportMeshAsync(null, path, modelName, this.scene )
      
      // save only root
      var meshes = importedModel.meshes
      var rootNodes = meshes.filter((node) => { 
        return node.name == "__root__"
      })[0]
      
      // Rename root
      rootNodes.name = modelName
      rootNodes.furnitureTypeSymbol = furnitureTypeSymbol
      rootNodes.originalModelName = modelName.split('.')[0]
      rootNodes.templateType = 'ELEMENT'
      rootNodes.id = furnitureTypeSymbol

      // Opimalization and select mesh textureAble
      rootNodes.getChildren().forEach(element => {
        element.freezeWorldMatrix();
        element.doNotSyncBoundingInfo = true;
        if( !element.name.includes('A_')  ){
          try{
            element.material.dispose()
          }catch(e){
            // material null problem
          }
          element.addTexture = true
        }
      });
      
      // Change texture
      this.changeModelMeshTexture(  rootNodes )

      // setEnabled model to FALSE , that can be use as template for instances
      rootNodes.setEnabled(false)

      this.baseInstances.push(rootNodes)

      return rootNodes
    },
    async addModel(path, modelName, furnitureTypeSymbol=null, modelApiData, instanceId ){
      /* add model instance to the scene */
      // await this.toogleFreezeActiveMeshes(false)
      this.debugModelname = modelName
      // console.log('connectorconnectorconnector MODEL NAMME', modelName)

      if( this.numberOfAllInstances > 1 && this.pickedAddbutton.parent ){
        if( this.sideToExtend == null  ){
          this.hideGUIButtonForPickedModel()
          this.setModelToHighlight({})
          this.pickedAddbutton = {}
          return 0
        }
      }

      this.sceneIsBussy = true
      // import model if not imported yet
      let model = this.scene.getMeshByName( modelName )
      if( model == undefined ){
        model = await this.importModel(path, modelName, furnitureTypeSymbol)
      }

      // create formated instance
      var instance = this.makeModelInstance(model, instanceId)
      instance.leftSideTakenBy = null;
      instance.rightSideTakenBy = null;
      instance.rotationValue = 0
      instance.furnitureTypeSymbol = furnitureTypeSymbol
      instance.modelApiData = modelApiData
      
      // change pivot position
      if( this.numberOfAllInstances <= 1 && this.sideToExtend == null){
          instance.position = new BABYLON.Vector3.Zero()
      }else{
        // reposition of model
        this.changeInstancePivotPointWithRotation( instance )
        await this.handleSideTakenByInstance(instance)
        instance.position = new BABYLON.Vector3(this.pickedAddbutton.absolutePosition.x, 0, this.pickedAddbutton.absolutePosition.z)
      }
      
      // assign asrmrest
      if( this.armrests['LEFT'] ){
        await this.assignArmrestToTheModelToBothSides(instance)
      }

      // count model on scene
      this.modelCounter(furnitureTypeSymbol, 'ADD' , false)
      this.instanceOnTheScene.push( instance )

      this.resetHithlight()
      // this.scene.whenReadyAsync().then(() => this.checkSceneIsReady() );


      return instance
      

    },
    makeModelInstance(model, instanceId){
      // instance
      this.nodesInstanceCounter++
      var parentOfInstance = new BABYLON.TransformNode( "instance_"+this.nodesInstanceCounter+"_"+model.name )
      parentOfInstance.instanceId = instanceId == null ? this.nodesInstanceCounter : instanceId
      parentOfInstance.rotationValue = 0
      parentOfInstance.modelName = model.name
      parentOfInstance.clickable = true
      parentOfInstance.templateType = model.templateType

      model.getChildren().forEach(child => {
          if( child.getClassName() == 'Mesh' ){

            // make instance if mesh
            var instance  = child.createInstance( child.name.toUpperCase() )
          }else{

            // make new transf Node
            var instance  = new BABYLON.TransformNode( child.name )

          }
          instance.position = child.position
          instance.parent = parentOfInstance
          
      });


      var colider = BABYLON.MeshBuilder.CreateBox("A_colider_"+this.nodesInstanceCounter, {height: 2, width: 2, depth: 2});
      colider.checkCollisions = true;
      colider.collisionRadius = new BABYLON.Vector3(3, 3, 3);
      colider.isVisible = false
      colider.parent = parentOfInstance

      if( instanceId != null && this.nodesInstanceCounter < instanceId ){
        this.nodesInstanceCounter = instanceId
      }


      return parentOfInstance
    },
    removeInstanceFromTheScene(model){
      /* Rremove instance from the scene and unlock attached connectors */
      this.instanceOnTheScene = this.instanceOnTheScene.filter(function(el) { return el.instanceId != model.instanceId  });

      // if(model.templateType == "ACCESSORY" ){
      //   if( model.parent.name.includes('A_ELEMENT_LEFT') ){
      //     model.parent.parent.leftSideTakenBy = null
      //   }
      //   if( model.parent.name.includes('A_ELEMENT_RIGHT') ){
      //     model.parent.parent.rightSideTakenBy = null
      //   }
      // }

      model.dispose()
      this.setFreeTakenSideAfterElementDelete( model.instanceId )
      this.modelCounter( model.furnitureTypeSymbol, 'SUBS', model.templateType == "ACCESSORY" )
      
      this.resetHithlight()
      this.repositionCamera()
      this.$parent.refreshPrice()

    },
    setFreeTakenSideAfterElementDelete( instanceId ){
      this.scene.rootNodes.forEach( node => {
        if( node.instanceId ){
          if( node.leftSideTakenBy == instanceId ){
            node.leftSideTakenBy = null
          }
          if( node.rightSideTakenBy == instanceId ){
            node.rightSideTakenBy = null
          }
        }
      })
    },
    modelCounter(furnitureTypeSymbol, type , accessory){
      if(!this.modelOnScene[furnitureTypeSymbol]){
        this.modelOnScene[furnitureTypeSymbol] = {
          quantity:1,
          furnitureTypeSymbol:furnitureTypeSymbol,
          accessory:accessory
        }
      }else{
        if( type == 'ADD' ){
          this.modelOnScene[furnitureTypeSymbol].quantity ++ 
        }else{
          this.modelOnScene[furnitureTypeSymbol].quantity --
        }
      }

    },
    changeInstancePivotPointWithRotation( instance ){
      
      // get element side mesh
      var addElementMesh =  this.getSideElementMeshFromInstanceElemenName(instance, this.pickedAddbutton.parent.name )
      var pivotAt = addElementMesh.position;
      const translation = instance.position.subtract(pivotAt)
      // add rotation value
      instance.rotationValue += this.highlightedModel.rotationValue + this.extraRotationValue
      if( addElementMesh.name.includes('!R') ){
        var Rvalue = parseInt( addElementMesh.name.split("!R")[1] )
        instance.rotationValue += Rvalue
      }

      var axis = BABYLON.Axis.Y;
      instance.rotateAround(pivotAt, axis, BABYLON.Tools.ToRadians( instance.rotationValue ) )
      instance.setPivotMatrix(BABYLON.Matrix.Translation(translation.x, 0, translation.z),false);
      

    },
    handleSideTakenByInstance(instance){
      var side = this.pickedAddbutton.parent.name.includes("LEFT") ? "LEFT" : "RIGHT"

      if( side == "LEFT" ){
        this.highlightedModel.leftSideTakenBy = instance.instanceId
        instance.rightSideTakenBy = this.highlightedModel.instanceId
      }
      else if( side == "RIGHT" ){
        this.highlightedModel.rightSideTakenBy = instance.instanceId
        instance.leftSideTakenBy = this.highlightedModel.instanceId
      }
    },
    getSideElementMeshFromInstanceElemenName(instance,instanceElementSide){
      var side = instanceElementSide.includes("LEFT") ? "RIGHT" : "LEFT"
      var addElementMesh = instance._children.filter((element) =>{
        return element.name.includes('A_ELEMENT_'+side)
      })[0];
      return addElementMesh
    },

    // // A C C E S S O R Y // //
    async importAccessories(path , fileName, modelName, type){
      /* import from file ( gltf format ) for the first time */

      var importedModel = await BABYLON.SceneLoader.ImportMeshAsync( null, path, fileName, this.scene )
      var meshes = importedModel.meshes
      var accessory = null
      meshes.forEach(mesh => {
        mesh.type = 'accessory'
        if( mesh.name == modelName ){
          accessory = mesh.parent
          accessory.setParent(null)
        }else{
          mesh.setEnabled(false)
        }
      });

      accessory.name = 'ACCESSORY_'+type
      accessory.furnitureTypeSymbol = type
      accessory.templateType = 'ACCESSORY'
      accessory.originalModelName = fileName.split('.')[0]

      // Opimalization and select mesh textureAble
      accessory.getChildren().forEach(element => {
        element.freezeWorldMatrix();
        element.doNotSyncBoundingInfo = true;
        if( !element.name.includes('A_')  ){
          try{
            element.material.dispose()
          }catch(e){
            // material null problem
          }
          element.addTexture = true
        }
      });

      // Change texture
      this.changeModelMeshTexture(accessory)

      // setEnabled model to FALSE , that can be use as template for instances
      accessory.setEnabled(false)

      return accessory
    },
    async addAccessory(path , modelName, accessoryModelName, furnitureTypeSymbol , accessoryType, isRightExtend, modelApiData, instanceId = null ){
      /* add accessoty instance to picked model on the scene */
      // await this.toogleFreezeActiveMeshes(false)
      this.nodesInstanceCounter++
      var instanceIds = instanceId == null ? this.nodesInstanceCounter : instanceId
      
      // ENDPART
      // console.log( 'accessoryTypeaccessoryType', accessoryType, isRightExtend , instanceIds, this.pickedAddbutton.parent  )
      if( !this.pickedAddbutton.parent ){
        // if endpart
        if( accessoryType == 'EndPart'){
          var endpartValid = await this.addEndpartToSide(isRightExtend, instanceIds)
          if(!endpartValid){
            return 0
          }
        }else{
          return 0
        }
      }
      this.sceneIsBussy = true
      // import model if not imported yet
      let model = this.scene.getTransformNodeByName( 'ACCESSORY_'+furnitureTypeSymbol )
      if( model == undefined ){
        model = await this.importAccessories(path , modelName, accessoryModelName, furnitureTypeSymbol)
      }

      // create instance of accessory instanceId
      var accessoryInstance = this.createAccessoryInstance( model )
      accessoryInstance.name = accessoryInstance.name + '_instance'
      accessoryInstance.furnitureTypeSymbol = furnitureTypeSymbol
      accessoryInstance.modelApiData = modelApiData
      accessoryInstance.parent = this.pickedAddbutton.parent
      accessoryInstance.instanceId = instanceIds
      accessoryInstance.parentInstanceId = this.highlightedModel.instanceId
      accessoryInstance.position = new BABYLON.Vector3.Zero()
      accessoryInstance.setEnabled(true)

      // rotate
      if( this.pickedAddbutton.parent.name.includes('!R') ){

        var rotationValue = parseInt( this.pickedAddbutton.parent.name.split("!R")[1] ) * -1
        // this.pickedAddbutton.parent.rotateAround(this.pickedAddbutton.parent.position, BABYLON.Axis.Y, BABYLON.Tools.ToRadians( rotationValue ) )
        this.pickedAddbutton.parent.rotation.y = BABYLON.Tools.ToRadians( rotationValue )
        try{
          this.pickedAddbutton.parent._children[1].rotationValue =  this.pickedAddbutton.parent.parent.rotationValue + rotationValue
        }catch(e){
          // no rotation value
          this.pickedAddbutton.parent._children[0].rotationValue =  this.pickedAddbutton.parent.parent.rotationValue + rotationValue
        }
      }else{
        try{
          this.pickedAddbutton.parent._children[1].rotationValue =  this.pickedAddbutton.parent.parent.rotationValue
        }catch(e){
          // no rotation value
          this.pickedAddbutton.parent._children[0].rotationValue =  this.pickedAddbutton.parent.parent.rotationValue
        }
      }

      if( instanceId != null && this.nodesInstanceCounter < instanceId ){
        this.nodesInstanceCounter = instanceId + side
      }

      this.modelCounter(furnitureTypeSymbol, 'ADD' , true)
      this.instanceOnTheScene.push( accessoryInstance )
      this.$parent.refreshPrice()
      this.resetHithlight()

      if( accessoryType == 'EndPart'){
        var side  =  isRightExtend ? "RIGHT" : "LEFT"
        accessoryInstance.instanceId = 'endpart'+side
      }

      return true

    },
    createAccessoryInstance(model){
      var parentOfInstance = new BABYLON.TransformNode( model.name )
      parentOfInstance.rotationValue = 0
      parentOfInstance.modelName = model.name
      parentOfInstance.clickable = true
      parentOfInstance.templateType = model.templateType

      model.getChildren().forEach(child => {
          if( child.getClassName() == 'Mesh' ){
            // make instance if mesh
            var instance  = child.createInstance( child.name )
          }else{
            // copy transformnode else
            instance = new BABYLON.TransformNode( child.name )
          }
          instance.position = child.position
          instance.parent = parentOfInstance
      });

      return parentOfInstance
    },
    async addEndpartToSide(isRightExtend, instanceId){
      var side  =  isRightExtend ? "RIGHT" : "LEFT"
      var mergePoint = null
      var validation = false

      this.scene.rootNodes.forEach(node => {
        if( node.name.includes('instance_') ){
          var hasRightArmrest = this.$parent.searchElementByFurnitureType(node.furnitureTypeSymbol)['hasRightArmrest']
          var hasLeftArmrest = this.$parent.searchElementByFurnitureType(node.furnitureTypeSymbol)['hasLeftArmrest']

          if( node.templateType == 'ELEMENT' && node.name.includes('instance_') ){
            
            if( side == 'LEFT' && node.leftSideTakenBy == null ){
              if( hasLeftArmrest ){
                // alert("Nie można dodać elementu, ponieważ miejsce jest zajęte!")
                validation = false
              }else{
                mergePoint = node.getChildren().filter( child => {
                  return child.name.includes('A_ELEMENT_'+side)
                })[0]
                this.pickedAddbutton.parent = mergePoint
                node.leftSideTakenBy  = 'endpart' + side
                validation = true
              }
            }
            else if( side == 'RIGHT' && node.rightSideTakenBy == null ){
              if( hasRightArmrest ){
                // alert("Nie można dodać elementu, ponieważ miejsce jest zajęte!")
                validation = false
              }else{
                mergePoint = node.getChildren().filter( child => {
                  return child.name.includes('A_ELEMENT_'+side)
                })[0]
                this.pickedAddbutton.parent = mergePoint
                node.rightSideTakenBy  = 'endpart'+side
                validation = true
              }
            }
            // console.log('validationvalidation', node.name , validation )
          }
        }
      });

      return validation
    },

    // // A R M R E S T // //
    async importArmrestModel(path, fileName, type){
      /* import from file ( gltf format ) for the first time */

      var elements = ['D_ARMREST_'+type+'-l','D_ARMREST_'+type+'-r', 'D_LEG-SET_main' ]

      var importedModel = await BABYLON.SceneLoader.ImportMeshAsync( elements, path, fileName, this.scene )

      var meshes = importedModel.meshes
      
      var rootNodes = meshes.filter((node) => { 
        return node.name == "__root__"
      })[0]
      
      rootNodes.name = 'ARMRESTS'
      rootNodes.templateType = 'ACCESSORY'
      rootNodes.setEnabled(false)

      this.armrests['LEFT'] = rootNodes.getChildren().filter(el=>{
        return el.name == 'D_ARMREST_'+type+'-l'
      })[0]
      this.armrests['LEFT'].position = new BABYLON.Vector3(0,0,0)

      this.armrests['RIGHT'] = rootNodes.getChildren().filter(el=>{
        return el.name == 'D_ARMREST_'+type+'-r'
      })[0]
      this.armrests['RIGHT'].position = new BABYLON.Vector3(0,0,0)

      // delete standard material
      this.armrests['RIGHT'].getChildren().forEach(child => {
        if( child.getClassName() == 'Mesh' ){
          child.material.dispose()
        }
      });

      this.armrestTextureHandler( this.armrests['RIGHT'], 'r' )
      this.armrestTextureHandler( this.armrests['LEFT'] , 'l' )

      return 0

    },
    makeDeepInstance(model){
      // instance
      // if(this.$parent.selectedArmrest == null ){
      //   return 0
      // }

      var parentOfInstance = new BABYLON.TransformNode( model.name )
      parentOfInstance.modelName = model.name

      model.getChildren().forEach(child => {
          if( child.getClassName() == 'Mesh' ){
            // make instance if mesh
            var instance  = child.createInstance( child.name )
          }else if( child.getClassName() == 'TransformNode' ){
            // copy transformnode else
            instance = new BABYLON.TransformNode( child.name )
          }
          
          instance.position = child.position
          instance.parent = parentOfInstance
          
      });

      return parentOfInstance
    },
    async changeArmrestModel(){
      if(this.$parent.selectedArmrest == null ){
        return 0
      }
      this.sceneIsBussy = true
      var armrest = this.scene.getMeshByName('ARMRESTS')
      armrest.dispose()

      await this.$parent.loadArmrest()
      this.scene.transformNodes.forEach(node => {
        if( !node.name ){
          // pass
        }
        else if(node.name.includes('instance_')){
          
          node.getChildren().forEach(child => {
            if( child.name.includes('D_ARMREST') ){
              child.dispose()
            }
          });

          this.assignArmrestToTheModelToBothSides(node)
          this.switchArmrestVisibility()

        }
        
      });
      
      this.scene.whenReadyAsync().then(() => this.checkSceneIsReady() );

    },
    assignArmrestToTheModelToBothSides(node){
      this.armrestsInstances = []
      this.assignArmrestToTheModelSide(node,'LEFT')
      this.assignArmrestToTheModelSide(node,'RIGHT')
    },
    assignArmrestToTheModelSide(modelInstance, side){
    /* Add armrest to model on both sides */
    if(this.$parent.selectedArmrest == null ){
        return 0
    }
    var mergePoint = modelInstance.getChildren().filter( child => {
      return child.name.includes('A_ELEMENT_'+side)
    })[0]

    var armrestInstance = this.makeDeepInstance( this.armrests[ side ] )

    armrestInstance.parent = mergePoint.parent
    armrestInstance.position = mergePoint.position
    if( mergePoint.name.includes('!R') ){
      var rotationValue = parseInt( mergePoint.name.split("!R")[1] ) * -1
      armrestInstance.rotateAround(mergePoint.position, BABYLON.Axis.Y, BABYLON.Tools.ToRadians( rotationValue ) )
    }
    armrestInstance.getChildren().forEach(child => {
      child.setEnabled(true)
    });
    
    this.armrestsInstances.push(armrestInstance)


    },
    switchArmrestVisibility(){
      this.scene.rootNodes.forEach(node => {
          if( !node.name ){
            // pass
          }
          else if( node.name.includes('instance_') ){
            // LEFT
            var hasLeftArmrest = this.$parent.searchElementByFurnitureType(node.furnitureTypeSymbol)['hasLeftArmrest']
            if( node.leftSideTakenBy || !hasLeftArmrest ){
              node.getChildren().filter(child => {
                return child.name.includes('D_ARMREST')
              })[0].setEnabled(false)

            }else{
              node.getChildren().filter(child => {
                return child.name.includes('D_ARMREST')
              })[0].setEnabled(true)
            }
            // RIGHT
            var hasRightArmrest = this.$parent.searchElementByFurnitureType(node.furnitureTypeSymbol)['hasRightArmrest']
            if( node.rightSideTakenBy || !hasRightArmrest ){
              node.getChildren().filter(child => {
                return child.name.includes('D_ARMREST') && child.name.includes('-r')
              })[0].setEnabled(false)
            }else{
              node.getChildren().filter(child => {
                return child.name.includes('D_ARMREST') && child.name.includes('-r')
              })[0].setEnabled(true)
            }
          }
      });
    },

    // // L E G S // //
    async initLegConnectors(path, fileName){

      await this.removePrevLegSet()
      var elements = ['D_LEG-SET_main']
      var ob = await BABYLON.SceneLoader.ImportMeshAsync( elements, path, fileName, this.scene )
      this.D_legSet = ob.meshes[0].getChildren()[0]
      this.D_legSet.setEnabled(false)
      
      return new Promise((resolve, reject) => {
        resolve(true)
      })
      
    },
    removePrevLegSet(){
      return new Promise((resolve, reject) => {
      this.scene.transformNodes.forEach(element => {
        if( element.name.toLowerCase() == 'd_leg-set_main' ){
          element.name = 'A'
          element.dispose()
        }
      });
      resolve(true)
      })
    },
    async preImportLegs(){
      this.legsImportInProgress = true
      if( this.$parent.legsModel == null ||  this.$parent.legsModel == {} ){
        // console.log('wait for legsModel . . . ')
        setTimeout(() => {
          this.preImportLegs()
        }, 1000);
      }else{
        await this.importLegs( this.$parent.legsModel['modelFullPath'] )
        this.legsImportInProgress = false
        return true
      }

    },
    async importLegs(path){
      /* import from file ( gltf format ) for the first time */

      var fileName = path.split('/')[ path.split('/').length - 1 ]
      var basePath = path.split( fileName )[0]
      this.legFileName = fileName

      var importedModel = await BABYLON.SceneLoader.ImportMeshAsync( null, basePath, fileName, this.scene )
      var meshes = importedModel.meshes
      var rootNodes = meshes.filter((node) => {
        return node.name == "__root__"
      })[0]

      rootNodes.name = 'LEGS'
      rootNodes.templateType = 'ACCESSORY'

      rootNodes.getChildren().forEach(element => {
        element.setEnabled(false)
      });


      return 0

    },
    getLegsMesh(attempt){
      return new Promise((resolve, reject) => {
        if( !this.scene.getMeshByName('LEGS') && attempt < 10 ){
          setTimeout(() => {
            resolve( this.getLegsMesh( attempt += 1 ) )
          }, 550);
        }else{
          resolve(true)
        }
      })
    },
    getLegsModel(legsModelName){
      var lowerLegsModelName = []

      legsModelName.forEach(e=>{
        if(  !lowerLegsModelName.includes( e.toLowerCase() ) ){
          lowerLegsModelName.push( e.toLowerCase() )
        }
      })
      var legsModel = this.scene.getMeshByName('LEGS').getChildren().filter(leg => {
          var legs = leg.getChildren().filter(legChild => {
            return lowerLegsModelName.includes( legChild.name.toLowerCase() ) == true
          });
          return legs.length > 0
        })

      return legsModel
    },
    async changeLegModel(){
      /* change leg model type */
      
      var dsa = await this.getLegsMesh(0)
      // console.log( 'changeLegModel dsadsadsadsa', this.$parent.legsModel.meshNames, dsa )
      
      // if( this.$parent.legsModel.meshNames.includes('leg-n146-190') ){
      //   this.$parent.legsModel.meshNames.push('leg-n146a-190')
      // }

      // new leg model
      try{

        this.mainLegModel = this.$parent.legsModel.meshNames[0].toLowerCase()
        var mainLegModelSymbol = this.mainLegModel.split('-').slice(1, -1);
        this.mainLegModelSymbol = mainLegModelSymbol.join('-');
        console.log('LEG MODEL ,', this.mainLegModelSymbol)
        this.legsModel = this.getLegsModel(this.$parent.legsModel.meshNames)
        this.legModel = this.legsModel[0]

        if( this.legsModel.length > 1 ){
          this.secLegModel = this.$parent.legsModel.meshNames[1].toLowerCase()
          this.secLegModelSymbol = this.secLegModel.split('-')[1]
        }
        
        await this.assignLegsConnectorsToModels().then(
          this.assignLegsToModels()
        )

        this.$parent.changeLegsTexture()
      
      }catch(e){
        console.warn('LEG PROBLEM', e)
      }


    },
    assignLegsConnectorsToModels(){
      return new Promise((resolve, reject) => {
        this.allConnectorsInstance = []
        this.instanceOnTheScene.forEach(element => {
          element.getChildren().forEach(chld => {
            if(chld.name.includes('A_LEG')){
              this.allConnectorsInstance.push(chld)
            }
          })
        })

        if(this.armrests['RIGHT']){
          this.armrestsInstances.forEach(element => {
            element.getChildren().forEach(chld => {
              if(chld.name.includes('A_LEG')){
                this.allConnectorsInstance.push(chld)
              }
            })
          })
        }

        // set allowed connectors
        this.allowedConnectors = []
        var allowedConnectorsNames = []
        var legsNames = []
        var uniqMeshNames = [...new Set(this.$parent.legsModel.meshNames)];
        uniqMeshNames.forEach(el =>{
          var mainLegModelSymbol = el?.split('-').slice(1, -1);
          var connectoName = mainLegModelSymbol.join('-');
          legsNames.push( connectoName )
        })
        
        legsNames.forEach(mainLegModelSymbol =>{
          var legConnectorName = "A_LEG_"+mainLegModelSymbol+"_ALWAYS"
          var legToConnectorNames = 'A_LEG_'+mainLegModelSymbol
          var legModelName = uniqMeshNames.filter(leg => leg.includes(mainLegModelSymbol) )[0]
          var ob = {
            legConnectorName:legConnectorName,
            legToConnectorNames:legToConnectorNames,
            legModelName:legModelName,
          }
          this.allowedConnectors.push( ob )
          allowedConnectorsNames.push( legConnectorName )
        })

        this.allConnectorsInstance.forEach(chld => {
            // reset D_LEG_SET child
            chld.getChildren().forEach(e => {
              e.dispose()
            });
            console.log('LEG MODEL NAME', chld.name.split('ALWAYS')[0]+"ALWAYS" )
            console.log('LEG MODEL NAME', allowedConnectorsNames )
            if( allowedConnectorsNames.includes( chld.name.split('ALWAYS')[0]+"ALWAYS"  ) || chld.name.includes("A_LEG-SET_main") ){
              // leg D_LEG_SET connector instance
              let DLegSetconnectorInstance = this.makeDeepInstance(this.D_legSet)
              DLegSetconnectorInstance.parent = chld
              this.scaleRotateLegs(chld, chld)
            }
        });
      
        resolve(true)
      })
    },
    assignLegsToModels(){
      this.allowedConnectors.forEach(leg => {
        var connectors = this.scene.transformNodes.filter(el =>{
          return el.name == leg.legToConnectorNames
        })

        connectors.forEach(con => {
          if( con.parent?.parent?.name.includes('A_LEG') ){
            // var legInstance = this.scene.getMeshByName(leg.legModelName).createInstance('LEG_INSTANCE')
            try{
              var legmodelMultipleSuf = con.parent?.parent?.name.split('_leg-')[1]
              var legmodelMultiple = 'leg-'+legmodelMultipleSuf
              var legInstance = this.scene.getMeshByName(legmodelMultiple).createInstance('LEG_INSTANCE')
            }catch{
              var legInstance = this.scene.getMeshByName(leg.legModelName).createInstance('LEG_INSTANCE')
            }

            // console.log('connectorconnectorconnector', con.name, con.parent?.parent?.name, leg.legModelName , this.debugModelname )
            legInstance.parent = con
            legInstance.position = new BABYLON.Vector3.Zero()
            var connectorParent = con.parent.parent.parent

            // hide
            if( connectorParent && connectorParent.name.includes('instance_')  ){
                this.hideLegIfModelConnectedToArmrest(legInstance, con.parent.parent )
                // if not corner
                if( !connectorParent.name.includes('corner')  ){
                  
                  // Hide leg on left side connected cons
                  var hasLeftArmrest = this.$parent.searchElementByFurnitureType(connectorParent.furnitureTypeSymbol)['hasLeftArmrest']
                  var hasRightArmrest = this.$parent.searchElementByFurnitureType(connectorParent.furnitureTypeSymbol)['hasRightArmrest']

                if( con.parent.parent.name.includes('RIGHT') ){
                  if( ( connectorParent.rightSideTakenBy && !connectorParent.rightSideTakenBy?.toString().includes('endpart') ) || hasRightArmrest ){
                    legInstance.parent.parent.setEnabled( false )
                  }else{
                    legInstance.parent.parent.setEnabled( true )
                  } 
                }
                if( con.parent.parent.name.includes('LEFT') ){
                  if( hasLeftArmrest ){
                    legInstance.parent.parent.setEnabled( false )
                  }else{
                    legInstance.parent.parent.setEnabled( true )
                  } 
                }

                }
            }
          }
        });

      });
    
      this.corrnerLegRule()
      this.legException()
    
    },
    addLegToConnector(connector){
        var legInstance = this.scene.getMeshByName(this.allowedConnectors[0].legModelName).createInstance('LEG_INSTANCE')
        legInstance.parent = connector
    },
    corrnerLegRule(){

      // check all corrner instance
      var instancesToHideLeftLeg = []
      var instancesToHideRightLeg = []
      this.instanceOnTheScene.forEach(element => {
        if( element.name.includes('corner') ){
          if( element.rightSideTakenBy ){
            instancesToHideLeftLeg.push( element.rightSideTakenBy )
          }
          if( element.leftSideTakenBy ){
            instancesToHideRightLeg.push( element.leftSideTakenBy )
          }
        }
      });

      // hide left legs
      instancesToHideLeftLeg.forEach(isntanceId => {
        var allInstanceToHideLeg = this.instanceOnTheScene.filter( instance =>{
          return instance.instanceId == isntanceId && !instance.name.includes('corner')
        })
        allInstanceToHideLeg.forEach(element => {
          element.getChildren().forEach(child =>{
            if( child.name.includes('A_LEG-SET') && child.name.includes('LEFT') ){
              child.getChildren()[0].setEnabled(false)
            }
          })
        });
      });

      // hide right legs
      instancesToHideRightLeg.forEach(isntanceId => {
        var allInstanceToHideLeg = this.instanceOnTheScene.filter( instance =>{
          return instance.instanceId == isntanceId && !instance.name.includes('corner')
        })
        allInstanceToHideLeg.forEach(element => {
          element.getChildren().forEach(child =>{
            if( child.name.includes('A_LEG-SET') && child.name.includes('RIGHT') ){
              child.getChildren()[0].setEnabled(false)
            }
          })
        });
      });

    },
    legException(){

      // ottoman
      this.instanceOnTheScene.forEach(element => {
        if( element.name.includes('chaise-longue') || element.name.includes('divan')  ){
          element.getChildren().forEach(child => {
            if(child._isEnabled){
              // show all leg connector
              if( child.name.includes('A_LEG-SET') ){
                child.getChildren()[0].setEnabled(true)
              }
              // hide armrest front leg connector
              if( child.name.includes('_ARMREST') ){
                child.getChildren().forEach(armChild => {
                  // front leg
                  if( armChild.name.includes('A_LEG') && !armChild.name.includes('_!') ){
                    armChild.getChildren()[0].setEnabled(false)
                  }
                });
              }
            }
          });
        }
      });

    },
    rotateLeg(leginstance, parent){
      if( parent.name.includes('!R') ){
        var rotationValue = parseInt( parent.name.split("!R")[1] ) * -1
        leginstance.rotateAround(leginstance.parent.position, BABYLON.Axis.Y, BABYLON.Tools.ToRadians( rotationValue ) )
        leginstance.position = new BABYLON.Vector3.Zero()
      }
    },
    scaleRotateLegs(legInstance, parent, scale=1){

      // Miroring 
      if(parent.name.includes('!FB_!LR')){
        legInstance.scaling = new BABYLON.Vector3( -scale , scale , -scale  )
      }
      else if(parent.name.includes('!LR')){
        legInstance.scaling = new BABYLON.Vector3( -scale , scale , scale  )
      }
      else if(parent.name.includes('!FB')){
        legInstance.scaling = new BABYLON.Vector3( scale , scale , -scale  )
      }

      // Rotation
      if( parent.name.includes('!R') ){
        var rotationValue = parseInt( parent.name.split("!R")[1] ) * 1
        parent.rotation.y = BABYLON.Tools.ToRadians( rotationValue )
      }

    },
    hideLegIfModelConnectedToArmrest(legInstance, parentInstance){

      var hasLeftArmrest = this.$parent.searchElementByFurnitureType(parentInstance.parent.furnitureTypeSymbol)['hasLeftArmrest']
      var hasRightArmrest = this.$parent.searchElementByFurnitureType(parentInstance.parent.furnitureTypeSymbol)['hasRightArmrest']

      if( parentInstance.name.includes('LEFT') ){
        if( hasLeftArmrest ){
          legInstance.parent.parent.setEnabled( false )
        }
      }
      if( parentInstance.name.includes('RIGHT') ){
        if( hasRightArmrest ){
          legInstance.parent.parent.setEnabled( false )
        }
      }

    },

    // // C A M E R A // // 
    midpoint(x1, z1, x2, z2) {
      var midpoint = [(x1 + x2) / 2, (z1 + z2) / 2]
      if( this.numberOfAllInstances < 2 ){
        midpoint = [0, 0]
      }
      
      return midpoint
    },
    angle(cx, cy, ex, ey) {
      var dy = ey - cy;
      var dx = ex - cx;
      var theta = Math.atan2(dy, dx); // range (-PI, PI]
      theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
      //if (theta < 0) theta = 360 + theta; // range [0, 360)
      return theta;
    },
    repositionCamera(){
      // console.log('this.numberOfAllInstances' , this.numberOfAllInstances)
      if( this.numberOfAllInstances <= 1 ){
        // pass
      }else{
      setTimeout(() => {
          try {
            this.camera.parent = this.boxTargetCamera
            var xMin = -10000
            var xMax = 10000
            var zMin = -10000
            var zMax = 10000

            this.instanceOnTheScene.forEach(element => {
                if( xMin < element.absolutePosition.x ){
                  xMin = element.absolutePosition.x
                }
                if( xMax >= element.absolutePosition.x ){
                  xMax = element.absolutePosition.x
                }
                if( zMin < element.absolutePosition.z ){
                  zMin = element.absolutePosition.z
                }
                if( zMax >= element.absolutePosition.z ){
                  zMax = element.absolutePosition.z
                }
            });

            if( this.instanceOnTheScene.length > 1 ){
              var midPoint = this.midpoint(xMin,zMin,xMax,zMax)
            }else{
              midPoint = [this.instanceOnTheScene[0].position.x, this.instanceOnTheScene[0].position.z ]
            }

            var animationcamera = new BABYLON.Animation(
                "myAnimationcamera",
                "position", 
                30, 
                BABYLON.Animation.ANIMATIONTYPE_VECTOR3, 
                BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
            );
            var keys = []
            var position = this.boxTargetCamera.position.clone()
            var centerPosition = new BABYLON.Vector3( midPoint[0],0,midPoint[1] )

            keys.push({
              frame: 0,
              value: position,
            });        
            keys.push({
              frame: 10,
              value: centerPosition
            });

            animationcamera.setKeys(keys);


            this.boxTargetCamera.animations = [];
            this.boxTargetCamera.animations.push(animationcamera);
            this.scene.beginAnimation(this.boxTargetCamera, 0, 100, false, 1 );

            this.camera.beta = 1.5 - this.numberOfAllInstances/20
            this.camera.radius = 40 + this.numberOfAllInstances*5

            } catch (error) {
              // console.log( 'ERROR ANIM', error )
            }
        }, 200 );
      }

    },

    // // I M P O R T  F R O M   J S O N // //
    async importAllModels(){
      /* import all models used in json file */
    },
    async importAllAccesories(){
      /* import all accesories used in json file */
    },
    async iterateThroughJSONdataElements(jsonData){
      
      // elements in order from right to left
      var elementsInOrder = []

      // collect all element in one array
      var allElements = []
      for (const element of jsonData){
        // unpack json
        var elements = JSON.parse(element.sc3dData)
        for( var elementsData of elements ){
          allElements.push(elementsData)
        }
      }

      // pick first element ( right edge )
      var firstElement =  allElements.filter(element => {
        return element.rightSideTakenBy == null || element.rightSideTakenBy.toString().includes('endpart')
      })[0]
      elementsInOrder.push( firstElement )
      var nextInstanceId = firstElement.leftSideTakenBy

      // push next elements
      while( nextInstanceId != 0 ){
        // pass
        var nextElement = allElements.filter(element =>{
          return nextInstanceId == element.instanceId
        })[0]
        nextInstanceId = nextElement ? nextElement.leftSideTakenBy : 0

        if(nextElement){
          elementsInOrder.push( nextElement )
        }

      }
      for (const element of elementsInOrder ){
        await this.$parent.loadModelsFromJson(element)
        await this.scene?.isReady()
        await this.timer(10);
      }

      this.resetHithlight()

      return true
    },
    async iterateThroughJSONdataAccessories(jsonData){

      // collect all element in one array
      var allAccessories = []
      for (const element of jsonData){
        // unpack json
        var elements = JSON.parse(element.sc3dData)
        for( var elementsData of elements ){
          allAccessories.push(elementsData)
        }
      }

      for( const accessory of allAccessories  ){
        // console.log( 'iterateThroughJSONdataAccessories', accessory )
        if( accessory.modelApiData['accessoryType'] == 'EndPart' ){
          var path = accessory['path']
          var modelObject = accessory['modelObject']
          var furnitureTypeSymbol = accessory['furnitureTypeSymbol']
          var modelApiData = accessory['modelApiData']
          var accessoryModelName = accessory.modelApiData['meshName']
          var accessoryType = accessory.modelApiData['accessoryType']
          var instanceId = accessory.instanceId
          var isRightExtend = accessory.modelApiData['isLeftExtend']
          await this.addAccessory(path , modelObject, accessoryModelName, furnitureTypeSymbol , accessoryType, isRightExtend, modelApiData, instanceId)
        }else{
          await this.$parent.loadAccessoryFromJson(accessory)
        }
      }

      this.engine.resize();
      this.$parent.selectedData.title = ''
      this.loadFromJsonStatus = true 

    }
  },
  watch:{
    'modelOnScene':{
        handler: function (after, before) {
          this.numberOfAllInstances = 0
          for (const [key, value] of Object.entries(after)) {
            if( value.accessory == false ){
            this.numberOfAllInstances += value.quantity
            }
          }
          try {
            this.repositionCamera()
            this.switchArmrestVisibility()
          } catch (error) {
            // pass
          }
        },
        deep: true
    },
    'sideToExtend':{
        handler: function (after, before) {

          if( after == before  ){
            return 0
          }

          let data = {
            sC3D:true
          }
          if(after) {
            // ACCESSORY
            if( after.includes('A_ACCESSORY') ){
              if( after.includes('neck') ){
                data['accessoryType'] = "HeadRest"
              }else if( after.includes('cushion') ){
                data['accessoryType'] = "Cushion"
              }
            }else{
              if( after.includes('R') ){
                data['isLeftExtend'] = true
              }else if( after.includes('L') ){
                data['isRightExtend'] = true
              }
            }

          }
          window.dispatchEvent(new CustomEvent('configurator3DData', {detail: data} ));

        },
        deep: true
    }
    
  }
  
}
</script>

<style scoped >

#renderCanvas{
    width: 100%;
    max-height: 90vh;
    display: none;
}
#renderCanvasScene{
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: 100%

}
#json-loader-background{
  background: white;
  width: 100%;
  height: 100%;

}
.non-visib{
  visibility: collapse;
}
.loader-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
  z-index: 5000;
}
.loader-container > div {
    position: absolute;
    left: 0;
    right: 0;
    top: 42%;
    margin: auto;
    width: 120px;
}

.path-load{
  fill: #fff;
  fill-opacity: 0.5;
  stroke: #000;
  stroke-width: 10;
  -webkit-animation: dash 1.5s linear forwards infinite alternate;
  animation: dash 1.5s linear forwards infinite alternate; 
}

.path-load-json{
  fill: #fff;
  fill-opacity: 0.5;
  stroke: #000;
  stroke-width: 10;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

</style>
