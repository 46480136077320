<template>
  <div id="root3dScene" class="scene" >
    <MyScene ref="myScene" :key="componentKey" />
  </div>
  <div class="hidden">
    <SideNav ref="sideNav" />
  </div>
<!-- sc@sits.pl -->
<!-- #Connet22#! -->

</template>

<script>

import MyScene from './components/MyScene.vue'
import MyScene2 from './components/MyScene2.vue'
import SideNav from './components/SideNav.vue'
import Footer from './components/Footer.vue'
import Summary from './components/Summary.vue'

import 'primeicons/primeicons.css';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    MyScene,
    MyScene2,
    SideNav,
    Footer,
    Summary
  },

  data() {
    return {
      componentKey:0,
      orderAmount:0,
      orderAmountUpdated:0,

      startFromAngular:false,
      sceneInnerHtmlTransfer:false,
      firstPickFamiltFlagFix:false,
      summaryView:false,
      addFirstModel:true,
      token:null,
      orderId:null,
      orderLineSetId:null,
      reciveFamilyName:'1300',
      customer:null,
      familyUpholsteryColorGroups:null,
      familyElements:null,
      familyAccessories:null,
      familyUpholsteries:null,
      familyComforts:null,
      familyCovers:null,
      familyArmrests:null,
      familyLegs:null,
      legHeights:null,
      legColors:null,
      familyModelTextures:null,
      familyArmrestTextures:null,
      familyLegModelTextures:null,
      sideNavDataEvent:null,
      price:null,
      formOrder:null,
      data:[],
      otherViewActive:false,

      selectionFromJson : false,
      selectionFromJsonData : {},

      selectedFamily:{},
      pickedAccessory:{},
      selectedData:{
          title:"Family",
          optionName:"Choose family",
          hasChips:true
      }
    }
  },
  computed: {
    selectedCover(){
      let data = {}
      try{
        data = this.formOrder.cover
      }catch(e){
      }
      return data
    },
    selectedComfort(){
      let data = {}
      try{
        data = this.formOrder.comfort
      }catch(e){
      }
      return data
    },
    selectedUpholstery(){
      let data = {}
      try{
        data = this.formOrder.upholstery
      }catch(e){
      }
      return data
    },
    selectedArmrest(){
      let data = {}
      try{
        data = this.formOrder.armrests
      }catch(e){
      }
      return data
    },
    selectedLegs(){
      let data = {}
      try{
        data = this.formOrder.legsHeight
      }catch(e){
      }
      return data
    },
    selectedLegsColor(){
      let data = {}
      try{
        data = this.formOrder.legsColor
      }catch(e){
      }
      return data
    },
    selectedLegsHeight(){
      let data = {}
      try{
        data = this.formOrder.legsHeight
      }catch(e){
      }
      return data
    },
    legsModel(){
      let data = {}
      try{
        data = this.formOrder.legsHeight
      }catch(e){
      }
      return data
    },
    legsColorName(){
      let data = {}
      try{
        data = this.formOrder.legsColor
      }catch(e){
      }
      return data
    },
    familyChips(){
      return [ this.selectedFamily['familyName'] ]
    },
    coverChips(){
      try{
        this.data[3]['chips'] = [ this.selectedComfort['comfortName'] , this.selectedCover['coverName'] ]
      }catch(e){
        // pass
      }
      return [ this.selectedComfort['comfortName'] , this.selectedCover['coverName'] ]
    },
    upholsteryChips(){
      try{
        this.data[2]['chips'] = [ this.selectedUpholstery['textileName']  ]
      }catch(e){
        // pass
      }
      return [ ]
    },
    legChips(){
      try{
        if( this.familyLegs.length != 0 ){
          this.data[5]['chips'] = [ this.selectedLegs['legSymbol'] , this.selectedLegsColor['colorName'],  this.selectedLegsHeight['heightSymbol'] ]
        }else{
          this.data[5]['chips'] = []
        }
      }catch(e){
        // pass
      }
    },
    elements(){
      var modelOnScene = this.$refs.myScene.modelOnScene
      var elements = []
      var id = 1
      try{
        for (var [name, properties] of Object.entries(modelOnScene)) {
          var id = id
          var ob =  {
                name:name,
                id:id,
                times:properties.quantity
          }
          id ++
          if( properties.quantity > 0 && !properties.accessory ) elements.push(ob)
        }
        this.getPrice(elements)
      }catch(e){
        // pass
      }
      return elements
    },

    sceneLegsImportInProgress(){
      var data = false
      try{
        data = this.$refs.myScene.legsImportInProgress
      }catch(e){
        // pass
      }

      return data
    }
  },
  mounted(){

    window.addEventListener('open-3d-config', evt => {
      if(evt.detail.order){
        this.orderAmount = evt.detail.order.amount
        this.selectedFamily = evt.detail.order
        this.formOrder = evt.detail.formOrder[3].value
        console.log('formOrder after configurator open ', this.formOrder )

        this.selectionFromJson = true
        this.selectionFromJsonData = evt.detail.order
      }
      this.initAppScene(evt.detail.customer)
    })
    
    window.addEventListener('close-3d-config', evt => {
        this.selectionFromJson = false
        this.selectionFromJsonData = {}
        this.deleteScene()
    })

    window.addEventListener('side-nav-item-click', evt => {
      
      if( evt.detail.params.config3D  ){

        this.sideNavDataEvent = evt
        let itemFromAngular = evt.detail.val
        this.formOrder = evt.detail.params.formOrder[3].value
        console.log('formOrder after click on side bar item ', this.formOrder )

        this.$refs.sideNav.selectItem( itemFromAngular , [] )

        // TEST
        if( this.formOrder.upholstery == null ){
          this.callForUpdateFormOrder()
        }
      }
    
    })

    window.addEventListener('form-order-update', evt => {
      this.formOrder = evt.detail.formOrder[3].value
        console.log('formOrder on formOrder any update ', this.formOrder )

      // // change leg
      // // console.log('new Price' , {... this.formOrder} , evt )
      this.orderAmountUpdated = {... this.formOrder}.totalValue
      try{
        // console.log('TESTUREDS', evt.detail )
        if( evt.detail.newValue['legSymbol'] ){
          this.formOrder.legsHeight = evt.detail.newValue
          this.$refs.myScene.changeLegModel()
          this.changeLegsTexture()
        }
        if( evt.detail.newValue['colorModelName']  ){
          this.formOrder.legsColor = evt.detail.newValue
          this.changeLegsTexture()
        }
      }catch(e){
        // pass
      }

    })

    window.addEventListener('change-side-nav-title', evt => {

      let navTitleMapper = {
        "getProductFamilies":"Family",
        "getFamilyElements":"Elements",
        "getFamilyUpholsteries":"Upholstery",
        "getFamilyArmrests":"Armrests",
        "getFamilyAccessories":"Accessories",
        "getFamilyLegs":"Legs",
        "getLegHeights":"Legs",
        "getLegColors":"Legs",

      }
      let navTitleFromAngular = evt.detail
      this.selectedData.title = navTitleMapper[ navTitleFromAngular ]

    })

    window.addEventListener('resize-3d-model', evt => {
        setTimeout(() => {
         this.$refs.myScene.engine.resize();  
        }, 10);
    })

    window.addEventListener('reset-sidebar-same', evt => {
      this.selectedData.title = 'reset'
    })


  },
  methods: {

    waitForFamilyDataFromAngular() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.selectedFamily);
        }, 3000);
      });
    },

    callForUpdateFormOrder(){
      window.dispatchEvent(new CustomEvent('call-for-update-form-order', {detail: true} ));
    },
    selectFamily(familyData){
      try{
        this.selectedFamily = familyData
        this.selectedFamily['familyModelName'] = this.selectedFamily['familyName'].split(' ')[0].toLowerCase()
      }catch(e){
        // pass
      }

    },
    forceRerenderScene() {
      this.componentKey += 1;  
    },
    blankCall(){
      return 0
    },
    async initAppScene(customer){
      this.customer = customer
      await this.deleteScene()
      await this.createScene()
    },
    deleteScene(){
      try {
        // console.log('VUEC CLOSE 3D')
        this.startFromAngular = false
        this.token = null
        this.$refs.myScene.engine.dispose()
        this.forceRerenderScene()

      } catch (error) {
        // pass
      }

      return 0
    },
    createScene(){
      window.dispatchEvent(new CustomEvent('transfer-scene-inner', {detail: "start"} ));
      document.querySelector("#renderCanvas").style.display = 'unset'
      if( !this.startFromAngular ){
        this.startFromAngular = true
      }
    },
    waitFOrData(data, title='', times=1){
      var timesLimit = 5
      if(!data){
        if(times > timesLimit){
          return false
        }
        setTimeout(() => {
          // console.log(' wait for '+ title )
          this.waitFOrData(data, title, times+1)
        }, 500);
      }
      return true
    },

    async login(){
      this.token = localStorage.getItem("sessionToken")
      await this.resetSelection()
    },
    async getNewOrderId(){
      var orderIdPayload = {
          "companySymbol": "Sits",
          "customerSymbol": "2244",
          "order": null,
          "customer": {
              "customerId": 49,
              "companySymbol": "Sits",
              "customerSymbol": "2244",
              "structureId": 74,
              "structureLevelId": 4,
              "name": "France Canape (GROUWEL) (2244)",
              "defaultLanguageId": 6,
              "countryId": 62,
              "structureName": "France Canape (GROUWEL) (2244)",
              "structureLevelName": "Client",
              "defaultLanguageName": "Français",
              "countryName": "FR",
              "currencyId": 6,
              "productMarket": "1"
          },
          "customerId": 49,
          "cReference": "TEST Configurator 3d",
          "cReferenceUnique": true,
          "note": "Note for testing",
          "deliveryAddress": {
              "addressId": 301,
              "customerId": 49,
              "addressIdRef": 35,
              "address": "25 RUE ESCOUVRIER\r\n95200 - SARCELLES\r\nFRANCJA",
              "addressEAN": "2244_35",
              "party": 608,
              "countryId": 62,
              "address1": "25 RUE ESCOUVRIER",
              "address2": null,
              "zipCode": "95200",
              "cityId": 3826,
              "county": null,
              "state": null,
              "inCity": 0,
              "name": "LTM",
              "countryName": "FR",
              "cityName": "SARCELLES",
              "addressTypes": [
                  {
                      "addressTypeId": 2,
                      "addressId": 301,
                      "symbol": "PAY",
                      "name": "PAY",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 4,
                      "addressId": 301,
                      "symbol": "PRIMARY",
                      "name": "PRIMARY",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 5,
                      "addressId": 301,
                      "symbol": "INVOICE",
                      "name": "INVOICE",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 6,
                      "addressId": 301,
                      "symbol": "DELIVERY",
                      "name": "DELIVERY",
                      "isDefault": true
                  },
                  {
                      "addressTypeId": 7,
                      "addressId": 301,
                      "symbol": "FINAL",
                      "name": "FINAL",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 8,
                      "addressId": 301,
                      "symbol": "VISIT",
                      "name": "VISIT",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 9,
                      "addressId": 301,
                      "symbol": "SECONDARY",
                      "name": "SECONDARY",
                      "isDefault": false
                  }
              ],
              "isDefaultFinalAddress": false,
              "isDefaultInvoiceAddress": false,
              "isDefaultDeliveryAddress": true,
              "isFinalAddress": true,
              "isInvoiceAddress": true,
              "isDeliveryAddress": true
          },
          "finalAddress": {
              "addressId": 266,
              "customerId": 49,
              "addressIdRef": 1,
              "address": "38 Rue de Courcelles\r\n75008 - Paris\r\nFRANCJA",
              "addressEAN": "2244_1",
              "party": 608,
              "countryId": 62,
              "address1": "38 Rue de Courcelles",
              "address2": null,
              "zipCode": "75008",
              "cityId": 3262,
              "county": null,
              "state": null,
              "inCity": 0,
              "name": "France Canape",
              "countryName": "FR",
              "cityName": "Paris",
              "addressTypes": [
                  {
                      "addressTypeId": 2,
                      "addressId": 266,
                      "symbol": "PAY",
                      "name": "PAY",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 4,
                      "addressId": 266,
                      "symbol": "PRIMARY",
                      "name": "PRIMARY",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 5,
                      "addressId": 266,
                      "symbol": "INVOICE",
                      "name": "INVOICE",
                      "isDefault": true
                  },
                  {
                      "addressTypeId": 6,
                      "addressId": 266,
                      "symbol": "DELIVERY",
                      "name": "DELIVERY",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 7,
                      "addressId": 266,
                      "symbol": "FINAL",
                      "name": "FINAL",
                      "isDefault": true
                  },
                  {
                      "addressTypeId": 8,
                      "addressId": 266,
                      "symbol": "VISIT",
                      "name": "VISIT",
                      "isDefault": false
                  },
                  {
                      "addressTypeId": 9,
                      "addressId": 266,
                      "symbol": "SECONDARY",
                      "name": "SECONDARY",
                      "isDefault": false
                  }
              ],
              "isDefaultFinalAddress": true,
              "isDefaultInvoiceAddress": true,
              "isDefaultDeliveryAddress": false,
              "isFinalAddress": true,
              "isInvoiceAddress": true,
              "isDeliveryAddress": true
          },
          "invoiceAddress": {
              "addressId": 266,
              "customerId": 49,
              "addressIdRef": 1,
              "address": "38 Rue de Courcelles\r\n75008 - Paris\r\nFRANCJA",
              "addressEAN": "2244_1",
              "party": 608,
              "countryId": 62,
              "address1": "38 Rue de Courcelles",
              "address2": null,
              "zipCode": "75008",
              "cityId": 3262,
              "county": null,
              "state": null,
              "inCity": 0,
              "name": "France Canape",
              "countryName": "FR",
              "cityName": "Paris",
              "isDefaultFinalAddress": true,
              "isDefaultInvoiceAddress": true,
              "isDefaultDeliveryAddress": false,
              "isFinalAddress": true,
              "isInvoiceAddress": true,
              "isDeliveryAddress": true
          },
          "wantedDeliveryDate": "2022-06-20T22:00:00.000Z",
          "deliveryAddressId": 301,
          "finalAddressId": 266,
          "invoiceAddressId": 266,
          "shipAddressId": 301
      }
      // var orderId = await this.revicePotsData('OrderAdd', orderIdPayload)
      this.orderId = "946313"
      
    },
    async refreshPrice(){

      let groupElements = {
      }
      let elementIndex = 0

      // group all instances on scene
      for( var instance of this.$refs.myScene.instanceOnTheScene ){

          let modelData  = instance.modelApiData
          let sc3dData = {}
          sc3dData.leftSideTakenBy = instance.leftSideTakenBy
          sc3dData.rightSideTakenBy = instance.rightSideTakenBy
          sc3dData.modelApiData = {...instance.modelApiData}
          sc3dData.instanceId = instance.instanceId
          sc3dData.parentInstanceId = instance.parentInstanceId
          sc3dData.parentName = instance.parent?.name
          sc3dData.name = instance.name
          sc3dData.id = instance.id
          sc3dData.modelName = instance.modelName
          sc3dData.position = instance.position
          sc3dData.furnitureTypeSymbol = instance.furnitureTypeSymbol
          sc3dData.rotation = instance.rotation
          sc3dData.rotationValue = instance.rotationValue
          sc3dData.path = modelData['modelFullPath'].split('models/')[0]+'models/'
          sc3dData.modelObject = modelData['modelFullPath'].split('models/')[1]

          if( instance.furnitureTypeSymbol in groupElements ){
            groupElements[ instance.furnitureTypeSymbol ].quantity ++
            groupElements[ instance.furnitureTypeSymbol ].sc3dData.push( sc3dData )
          }else{
            // add new key to 
            groupElements[ instance.furnitureTypeSymbol ] = {
              quantity:1,
              elementIndex:elementIndex,
              sc3dData:[ sc3dData ],
              furnitureTypeSymbol:instance.furnitureTypeSymbol,
              addFrom3d:true,
              blockCheckPrices:true,
              ...modelData

            }
          }
          elementIndex ++
      
      };
    
      let groupElementsArray = []  
      // for element in group elements
      for (const [key, element] of Object.entries(groupElements)) {
        groupElementsArray.push( element )
      }

      // console.log('groupElementsArraygroupElementsArray', groupElementsArray )

      // reset controls at first element
      groupElementsArray[0].resetControl = true
      groupElementsArray[ groupElementsArray.length - 1 ].blockCheckPrices = false

      groupElementsArray.forEach(element => {
        this.addModelToControls(element)
      });

      this.$refs.myScene.checkSceneIsReady()

    },
    addModelToControls(modelData){
      window.dispatchEvent(new CustomEvent('change-quantity-from-3d', {detail: modelData} ))
    },
    async addModel(path,modelName,furnitureTypeSymbol=null, item, instanceId = null){
      if( this.$refs.myScene.sceneIsBussy && !this.addFirstModel && !this.selectionFromJson ){
        return 0
      }
      if(this.$refs.myScene.numberOfAllInstances > 1 && this.$refs.myScene.sideToExtend == null){
        return 0
      }

      this.addFirstModel = false

      //  reset selection
      if( this.$refs.myScene.numberOfAllInstances <= 1 && this.$refs.myScene.sideToExtend == null ){
        this.$refs.myScene.modelOnScene = {}
        await this.$refs.myScene.deleteAllSceneModels()
        if( this.selectedArmrest != null  ){
          await this.loadArmrest()
        }
      }

      var instance = await this.$refs.myScene.addModel( path, modelName, furnitureTypeSymbol, item, instanceId )
      this.$refs.myScene.changeAllTextures()
      this.changeLegsTexture()

      return instance
    },
    async resetSelection(path=null, modelObject=null, furnitureTypeSymbol=null, item){
      this.$refs.myScene.modelOnScene = {}
      this.$refs.myScene.sceneIsBussy = true
      this.$refs.myScene.instanceOnTheScene = []
      this.addFirstModel = true
      await this.$refs.myScene.deleteAllSceneMeshes()

      if(furnitureTypeSymbol == null){
        await this.getFamilyElements()
        await this.getFamilyModelTextures()
        await this.getFamilyArmrestTextures()
        await this.getFamilyLegModelTextures()
        await this.loadLegConnectors()


        if( this.selectionFromJson ){
          await this.initDefaultModel(true)
          await this.$refs.myScene.iterateThroughJSONdataElements(this.selectionFromJsonData.elements )
          this.$refs.myScene.iterateThroughJSONdataAccessories(this.selectionFromJsonData.accessories )
        }else{
          await this.initDefaultModel()
        }

      }else{
          this.initNewBaseModel(path, modelObject, furnitureTypeSymbol, item)
      }

    },
    async initNewBaseModel(path, modelObject, furnitureTypeSymbol, item){
      while(  !this.$refs.myScene.scene.isReady() ) {
          // console.log('waiting for the scene ...')
      }
      this.$refs.myScene.preImportLegs()
      await this.$refs.myScene.deleteAllSceneMeshes()

      if( this.selectedArmrest != null ){
        await this.loadArmrest()
      }

      await this.addModel( path, modelObject, furnitureTypeSymbol, item )

    },
    async initDefaultModel(fromJson=false){
      

      while(  !this.$refs.myScene.scene.isReady() ) {
          // console.log('waiting for the scene ...')
      }
      this.$refs.myScene.preImportLegs()
      await this.$refs.myScene.deleteAllSceneMeshes()

      // load armrests
      if( this.selectedArmrest != null || this.formOrder.armrests ){
        await this.loadArmrest()
      }

      if(!fromJson){

        // select default model
        try{
          var defaultModel = this.familyElements.filter(element => {
              return element.isDefault && element.modelFullPath != null
          })[0]
        }catch(e){  
          // pass
        }
        if( defaultModel == undefined ){
          var defaultModel = this.familyElements.filter(element => {
              return element.modelFullPath != null
          })[0]
        }

        var path = defaultModel['modelFullPath'].split('models/')[0]+'models/'
        var modelObject = defaultModel['modelFullPath'].split('models/')[1]

        await this.addModel( path, modelObject , defaultModel['furnitureTypeSymbol'], defaultModel )

      }

    },

    async loadModelsFromJson(model){

      this.selectedData.title = 'Elements'

      var path = model['path']
      var modelObject = model['modelObject']
      var furnitureTypeSymbol = model['furnitureTypeSymbol']
      var modelApiData = model['modelApiData']

      if( this.selectedArmrest != null ){
        await this.loadArmrest()
      }
      var instance = await this.addModel( path, modelObject, furnitureTypeSymbol, modelApiData, model.instanceId )

      // console.log('redfvcxvcx',  model )

      // selectinstance mesh
      var instanceMeshes = instance.getChildren().filter(element => {
        return element.getClassName().includes('Mesh')
      })
      var instanceMesh = instanceMeshes[0]
      
      // click on instance mesh
      this.$refs.myScene.onMeshClick(instanceMesh)

      instance.getChildren().forEach(element => {
        if( element.name.includes('A_ELEMENT_LEFT') ){
          this.$refs.myScene.chnageButtonToPicked( element.getChildren()[0] )
        }
      });
      
      // wait for scene
      await this.waitFOrData( this.$refs.myScene.scene.isReady() , 'scene is ready' )

      return instance

    },
    async loadAccessoryFromJson(model){

      this.selectedData.title = 'Accessories'
      
      var path = model['path']
      var modelObject = model['modelObject']
      var furnitureTypeSymbol = model['furnitureTypeSymbol']
      var modelApiData = model['modelApiData']
      var accessoryModelName = model.modelApiData['meshName']
      var accessoryType = model.modelApiData['accessoryType']
      var instanceId = model.instanceId
      var parentInstanceId = model.parentInstanceId

      var parentinstance = this.$refs.myScene.instanceOnTheScene.filter(function(el) { return el.instanceId == parentInstanceId  })[0];
      
      parentinstance.getChildren().forEach(element => {
        if( element.name == model.parentName ){    
          this.$refs.myScene.onMeshClick(element)
          // console.log('dsaddsdsadsdsdsadas', {... element.getChildren()[0] } )
          this.$refs.myScene.chnageButtonToPicked( element.getChildren()[0] )
        }
      });

      await this.$refs.myScene.addAccessory( path, modelObject, accessoryModelName, furnitureTypeSymbol, accessoryType, false, modelApiData, instanceId )
      
      // wait for scene
      await this.waitFOrData( this.$refs.myScene.scene.isReady() , 'scene is ready' )

    },
    waitForDataFromAngular(attempt){
      return new Promise((resolve, reject) => {
        if( !this.formOrder.legsHeight && attempt < 10 ){
          setTimeout(() => {
            resolve( this.waitForDataFromAngular( attempt += 1 ) )
          }, 200);
        }else{
          resolve(true)
        }
      })
    },
    async loadLegConnectors(){
      await this.waitForDataFromAngular(0)
      try{
        var path = this.formOrder.legsHeight.modelFullPath.split('models/')[0]+'models/'
        var modelPath = this.formOrder.legsHeight.modelFullPath.split('models/')[1].replace('legs', 'shared')
        await this.$refs.myScene.initLegConnectors(path, modelPath)
        // console.log('LEG changeLegModel dsawwwwww ', this.$refs.myScene.D_legSet )
      }catch(e){
        // console.log( 'LEG changeLegModel dsawwwwww e', e )
      }

    },
    async loadArmrest(){

        var path = this.formOrder.armrests.armrestModelFullPath.split('models/')[0]+'models/'
        var modelPath = this.formOrder.armrests.armrestModelFullPath.split('models/')[1]

        await this.$refs.myScene.importArmrestModel(path, modelPath, this.selectedArmrest['armrestTypeSymbol'] )
        
    },
    async getPrice(sceneElements){
      if(sceneElements.length == 0) return 0

      var elements = []
      sceneElements.forEach(element => {
          var newObj = {
            FurnitureTypeSymbol:element.name,
            Quantity:element.times,
          }
          elements.push(newObj)
      });

      var familyFurnitureTypePricesPayload = {
        "companySymbol":this.selectedFamily['companySymbol'],
        "familySymbol":this.selectedFamily['familySymbol'],
        "customerSymbol":"2244",
        "comfortSymbol":this.selectedComfort['comfortSymbol'],
        "coverTypeSymbol":this.selectedFamily['coverTypeSymbol'],
        "coverSymbol":this.selectedCover['coverSymbol'],
        "textileSymbol":this.selectedUpholstery['textileSymbol'],
        "elements":elements,
        "accessories":[
        ]
      }
      try {
        var prices = await this.revicePotsData('FamilyFurnitureTypePrices', familyFurnitureTypePricesPayload)
        var currencySymbol = ' €'
        try{
          if(prices[0]['currencySymbol'] != 'EUR' ){
            currencySymbol = ' $'
          }
        }catch(e){
          // pass
        }

        var price = 0
        prices.forEach(pr => {
          var quantity = elements.filter(el=>{
            return el.FurnitureTypeSymbol == pr.furnitureTypeSymbol
          })[0].Quantity

          price += pr['salePrice'] * quantity
        });
        this.price = price + currencySymbol

      } catch (error) {
        // console.log('getPrice error', error)
      }
    },
    changeArmrest(type){
        var path = 'families/'+this.selectedFamily['familyModelName']+'/models/'
        var modelPath = this.selectedFamily['familyModelName']+'_shared'
        this.$refs.myScene.replaceArmrest(path, modelPath, type )
    },
    async getFamilyUpholsteryColorGroups(){

      var data = await this.reciveGetData( 'FamilyUpholsteryColorGroups' )
      this.familyUpholsteryColorGroups =  data
      // this.data[2].colors = data
      // this.familyUpholsteryColorGroups =  []
    },
    async getFamilyElements(){
      try{
        var body = this.selectedFamily
        body["CustomerSymbol" ] = this.customer['customerSymbol'] 
        var data = await this.revicePotsData( 'FamilyElements', body , 'furnitureTypeName')

        // only with sC3D flag
        this.familyElements = data.filter(element => {
          element['nameSelector'] = element['furnitureTypeName']
          return element.sC3D
        })

        // this.data[1].data = this.familyElements

      }catch(e){
        // console.log(e , 'getFamilyElements Error' , body )
      }
    },
    async getFamilyAccessories(){
      try{
        var body = this.selectedFamily
        body["CustomerSymbol" ] = this.customer['customerSymbol'] 
        var data = await this.revicePotsData( 'FamilyAccessories', body , 'furnitureTypeName')

        // only with sC3D flag
        this.familyAccessories = data.filter(element => {
          element['nameSelector'] = element['furnitureTypeName']
          return element.sC3D
        })
        this.data[6].data = this.familyAccessories

      }catch(e){
        // console.log(e , 'getFamilyAccessories Error' , body )
      }
    },
    async getFamilyUpholsteries(){
      try{
        var body = this.selectedFamily
        body['customerSymbol'] = this.customer['customerSymbol']
          
        var data = await this.revicePotsData( 'FamilyUpholsteries', body , 'textileName')
        this.familyUpholsteries =  data.filter(element => {
          return element.sC3D
        })
        this.data[2].data = this.familyUpholsteries
      }catch(e){
        // console.log(e , 'getFamilyUpholsteries Error' , body )
      }
    },
    getComfortAndCover(){
      this.getFamilyComforts()
      this.getFamilyCovers()
    },
    async getFamilyComforts(){
      try{
        var body = this.selectedFamily
        body['customerSymbol'] = this.customer['customerSymbol']
        var data = await this.revicePotsData( 'FamilyComforts', body, 'comfortName')
        this.familyComforts =  data
        this.data[3].data[0].data = this.familyComforts
      }catch(e){
        // console.log(e , 'getFamilyComforts Error' , body )
      }

    },
    async getFamilyCovers(){
      try{
        var body = this.selectedFamily
        body['customerSymbol'] = this.customer['customerSymbol']
        var data = await this.revicePotsData( 'FamilyCovers', body, 'coverName')
        this.familyCovers =  data
        this.data[3].data[1].data = this.familyCovers
      }catch(e){
        // console.log(e , 'getFamilyCovers Error' , body )
      }
    },
    async getFamilyArmrests(){
      try{
        var body = this.selectedFamily
        body['customerSymbol'] = this.customer['customerSymbol']
        body['CoverTypeSymbol'] = this.selectedCover['coverSymbol']

        var data = await this.revicePotsData( 'FamilyArmrests', body, 'armrestTypeSymbol')
        this.familyArmrests =  data.filter(element => {
          return element.sC3D
        })
        this.data[4].data = this.familyArmrests
        
      }catch(e){
        
      }

    },
    async getFamilyLegs(){
      // pass
    },
    async getLegHeights(){
      try{
        var sc3dHeight = ['100','120','140','200']
        var body = {
            "CompanySymbol": "SITS",
            "legSymbol": this.selectedLegs['legSymbol']
        }
        var data = await this.revicePotsData( 'LegHeights', body, 'heightSymbol')
        // this.legHeights =  data.filter(element => {
        //   return sc3dHeight.includes( element.heightSymbol )
        // })
        this.legHeights =  data
        this.data[5].data[2].data = this.legHeights

      }catch(e){
        // console.log(e)
      }
    },
    async getLegColors(){
      try{
        var body = {
            "CompanySymbol": "SITS",
            "legSymbol": this.selectedLegs['legSymbol']
        }
        var data = await this.revicePotsData( 'LegColors', body, 'colorName')
        this.legColors =  data
        this.data[5].data[1].data = this.legColors

      }catch(e){
      }

    },
    async getFamilyModelTextures(){
      try{
        var body = this.selectedFamily
        body['customerSymbol'] = this.customer['customerSymbol']
        body['coverTypeSymbol'] = this.selectedFamily['coverTypeSymbol']
        body['CoverTypeSymbol'] = this.selectedFamily['coverTypeSymbol']

        var data = await this.revicePotsData( 'FamilyModelTextures', body )
        this.familyModelTextures =  data

      }catch(e){
      }

    },
    async getFamilyArmrestTextures(){
      try{
        var body = this.selectedFamily
        var data = await this.revicePotsData( 'FamilyArmrestTextures', body , true )
        this.familyArmrestTextures =  data

      }catch(e){
      }

    },
    async getFamilyLegModelTextures(){
      try{
        var body = {
          "CompanySymbol":this.selectedFamily['companySymbol'],
          "FamilySymbol":this.selectedFamily['familySymbol']
        }
        // console.log('LEGTEXTUREss body' , body )

        var data = await this.revicePotsData( 'FamilyLegModelTextures', body )
        // console.log('LEGTEXTUREss data' , data)

        this.familyLegModelTextures =  data

      }catch(e){
        // console.log('LEGTEXTUREss ERROR' , e)
      }

    },
    async reciveGetData(endpoint){
      if(this.token==null) {//we want it to match
          setTimeout(() => {
            // console.log('Wait for token ... ')
            this.reciveGetData(endpoint)
          }, 100 );
          return;
      }

      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer "+this.token
            }
      };
      var data = await axios.get('scapi/'+endpoint, axiosConfig)
      .then((res) => {
        data = res['data']
        return data
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      })
      return data['data']
    },
    async revicePotsData(endpoint, body, nameSelector=null, authorization=true){
      axios.defaults.headers.common['Content-Type'] = "application/json"
      axios.defaults.headers.common['Accept'] = "application/json"
      this.token = localStorage.getItem("sessionToken")
      if(authorization){
        var axiosConfig = {
          headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type":"application/json",
                processData: false,
                Authorization: "Bearer "+this.token
              }
        };
      }else{
        axiosConfig = {
          headers: {
                "Access-Control-Allow-Origin": "*",
                processData: false,
              
              }
        };
      }

      // console.log( 'axiosConfigaxiosConfig', body , axiosConfig )

      var data = await axios.post('scapi/'+endpoint, body, axiosConfig)
      .then((res) => {
        data = res['data']
        return data
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err , axiosConfig);
      })

      if(authorization && nameSelector){
        data['data'].forEach(element => {
          element['nameSelector'] = element[nameSelector]
        });
      }

      return data['data']

    },
    changeUpholstery(){
      this.$refs.myScene.changeAllTextures()
    },
    changeLegsTexture(){
      try{
        this.familyLegModelTextures.forEach(element => {
          var legColors = element.colors
          var legMeshName = element.meshName
          var legMesh = this.$refs.myScene.scene.meshes.filter(element =>{
            return element.name == legMeshName
          })[0]

          var legTexture =  legColors.filter(texture => {
            return texture.colorModelName == this.legsColorName['colorModelName']
          })[0]
                    
          if( legTexture ){
            try{
              var textureUrl = legTexture['textureFullPath']
              this.$refs.myScene.chnageLegTextures(legMesh, textureUrl)
            }catch(e){
              // console.log('LEG MESH TEXTURE ERROR :', e)
              // pass
            }
          }else{
            // console.log('LEG MESH TEXTURE : NO LEG TEXTURE')
            // pass
          }

        });

      }catch(e){
          // pass
      }

      return true

    },
    showSummary(){
      this.summaryView = true
      setTimeout(() => {
        this.$refs.myScene.engine.resize();
      }, 10);
    },
    hideSummary(){
      this.summaryView = false
      setTimeout(() => {
        this.$refs.myScene.engine.resize();
      }, 10);

    },
    searchElementByFurnitureType(typeSymbol){
      var element = this.familyElements.filter(element => {
        return element.furnitureTypeSymbol == typeSymbol
      })[0]

      return element
    },
    getTextureByMeshName(modelName , meshName){
      
      if( !this.selectedUpholstery ){
        // console.log('wait for selectedUpholstery . . . ')
        setTimeout(() => {
          this.callForUpdateFormOrder()
          this.getTextureForArmrest('LEFT')
        }, 1000);
      }

      if( modelName == 'endpart'){ 
        modelName = 'end-parts'
      }

      var texture = this.familyModelTextures.filter(element => {
        return element.modelName.toLowerCase() == modelName && element.meshName.toLowerCase() == meshName.toLowerCase()
      })[0]

      // end-part temp bypass
      if( !texture ){
        texture = this.familyModelTextures.filter(element => {
          return element.modelName.toLowerCase() == this.selectedFamily.familyModelName+"_"+modelName.toLowerCase()
        })[0]
      }

      var texturePath = texture['textiles'].filter(tp=>{
        return tp.textileSymbol == this.selectedUpholstery['textileSymbol'] && tp.textureFullPath != null
      })
      
      if( texturePath.length > 0 ){
        texturePath = texturePath[0]
      }else{
        return false
        // texturePath = texture['textiles'][0]
      }

      return texturePath['textureFullPath']
    },
    getTextureForArmrest(side){
      // temp texture
      if( !this.selectedUpholstery ){
        // console.log('dsadsadsadsa wait for selectedUpholstery . . . ')
        setTimeout(() => {
          this.callForUpdateFormOrder()
          this.getTextureForArmrest(side)
        }, 1000);
      }

      var modelTexturesLeft =  this.familyArmrestTextures.filter(el =>{
        return el['meshName'].includes(this.selectedArmrest['armrestTypeSymbol']) && el['meshName'].includes( '-'+side ) 
      })[0]


      var texture = modelTexturesLeft.textiles.filter(el =>{
        return el['textileSymbol'].toLowerCase()  == this.selectedUpholstery['textileSymbol'].toLowerCase()  
      })

      if( texture.length > 0 ){
        texture = texture[0]
      }else{
        return false
        // texture = modelTexturesLeft.textiles[0]
      }

      return texture

    },
    searchAccessoryByFurnitureType(typeSymbol){
      var element = this.familyAccessories.filter(element => {
        return element.furnitureTypeSymbol == typeSymbol
      })[0]
      return element
    },
    searchElementTextureByName(name){
      // TEMP FIX FOR ENDPART
      if( name == 'endpart') name = 'end-parts'

      var texture = this.familyModelTextures.filter(element => {
        return element.modelName == this.selectedFamily.familyModelName+"_"+name
      })[0]

      var texturePath = texture['textiles'].filter(tp=>{
        return tp.textileSymbol == this.selectedUpholstery['textileSymbol']
      })[0]
      
      return texturePath.textureFullPath
    }
  },
  watch:{
    'selectedData.title':{
        handler: function (after, before) {
          if(after == 'reset'){
            this.$refs.myScene.toogleFreezeActiveMeshes(true)
          }else{
            this.$refs.myScene.toogleFreezeActiveMeshes(false)
          }
        },
        deep: true
    },
    'startFromAngular':{
        handler: function (after, before) {
          if( after == true && this.token == null ){
            this.login()
          }
        },
        deep: true
    },
    'sceneLegsImportInProgress':{
        handler: function (after, before) {
          // console.log('AFRERTRTR', after )
        },
        deep: true
    }
  }
}
</script>

<style scoped>
path{
    fill: unset;
    fill-opacity:unset;
    stroke: unset;
    stroke-width: unset;
    stroke-dasharray: unset;
    stroke-dashoffset: unset;
}


body{
    margin: 0;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background: #f9f9fb;
}
.hidden{
  display: none;
}
.non-visib{
  visibility: collapse;
}
.white{
    color: white
}
.grey{
    color: #9699A0 !important
}
.black{
    color: black
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #F9F9FB;
  margin:0;
}
.scene-summary{
  width: 50%;
}
#summary{
  width: 49%;
  background: #FFFFFF;
  box-shadow: 0px 3px 25px #00000017;
  height: 90vh;
  margin: unset;
  float: right;
  border-left:solid 2px #e1e1e1;
}
#sidenav{
  width: 25%;
  background: #FFFFFF;
  box-shadow: 0px 3px 25px #00000017;
  height: 90vh;
  margin: unset;
  float: right;
  border-left:solid 2px #e1e1e1;
}

#sidenav-other{
    position: absolute;
    top: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 25px #00000017;
    min-height: 100vh;
    z-index: 2;
    width: 25%;
    transition:left .5s;
    border-left:solid 2px #e1e1e1;
    overflow-y: auto;
    height: 0vh;
}
.side-active-true{
  left: 74.9%;
}
.side-active-false{
  left: 100%;
}

.footer{
  position:fixed;
  bottom:0;
  left: 0;
  height: 10vh;
  width:100%;


}

</style>
