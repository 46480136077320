<template>
<div class="footer-content" >
    <div class="price grey" >
        Price - <span class="black">{{$parent.price}}</span>
    </div>
    <div @click="showSummary()" class="sumarry grey" >
        <div class="summary-content" >
            Summary
            <i class="pi pi-arrow-right white" ></i>
        </div>
    </div>
</div>

</template>

<script>

export default {
  name: 'Footer',
  props: {
      price:Number,
  },
  data() {
    return {
    }
  },
  methods:{
      showSummary(){
          this.$parent.showSummary()
      }
  }
}
</script>

<style>
.footer-content{
    position:relative;
    box-shadow: 0px 3px 25px #00000017;
    z-index: 1;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    height:99%;
    padding-left:108px
}
.sumarry{
    position: absolute;
    right:0%;
    width: 240px;
    background:#2E3341;
    height:100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.summary-content{
    text-align: center;
    width: -webkit-fill-available;

}
.summary-content > i{
    margin-left: 5px;
}


</style>
