<template>
    <div :class="{'sidenav-content': mainView , 'sidenav-content-borderless': !mainView }" >
        <div  class="content-title">
            <div v-if="!mainView" id="title-back-icon">
                <i @click="$parent.otherViewActive = false , $parent.$refs.myScene.resetHithlight()" class="pi pi-angle-left"></i>
            </div>
            <div v-if="!mainView" class="title grey thin" >
                {{$parent.selectedData.optionName}}
            </div>
            <div v-else class="title grey thin" >
                Configuration
            </div>
            <div v-if="!mainView" id="title-back-icon">
                <i @click="$parent.otherViewActive = false , $parent.$refs.myScene.resetHithlight()" class="pi pi-angle-left"></i>
            </div>
        </div>

        <!-- Main View of configurator ( page 1 ) -->
        <div v-if="mainView" >
            <div v-for="brick in data" :key="brick.title" class="content-main main-page">
                <div class="content-main-main" >
                    <div class="elements" v-if="brick.title == 'Elements' " >
                        <div class="elements-title">
                            Elements
                        </div>
                        <div v-for="item in elements" :key="item.id" class="elements-items">
                            <div class="element-lp" >
                                {{ elements.indexOf(item)+1 }}
                            </div>
                            <div class="element-name">
                                {{ $parent.searchElementByFurnitureType(item.name).furnitureTypeName }}
                            </div>
                            <div class="element-times">
                                x{{ item.times }}
                            </div>
                            
                        </div>
                    </div>
                    <div v-else class="title" >
                        {{ brick.title }}
                    </div>
                    <div class="content-main-forward-icon grey">
                        <i v-if="brick.iconAdd" class="pi pi-plus right" @click="   $parent.otherViewActive = true,
                                                                                    $parent.selectedData = brick, 
                                                                                    $parent.selectedData.functionCall() " ></i>
                        <i v-else   class="pi pi-angle-right right" 
                                    @click="
                                            $parent.otherViewActive = true,
                                            $parent.selectedData = brick, 
                                            $parent.selectedData.functionCall() " >
                        </i>

                    </div>
                    
                </div>
                <div class="main-chips" v-if="brick.hasChips" >
                    <div v-for="chip in brick.chips" :key="chip" class="chip">
                        {{ chip }}
                    </div>
                </div>
            </div>
            
        </div>

        <div v-else >
            <!-- Search -->
            <div v-if="$parent.selectedData.search" class="search-element content-title" >
                <i class="pi pi-search" ></i>
                <AutoComplete v-model="selectedItem" :suggestions="filteredItems" @complete="searchItems"  field="label" placeholder="Search element" />
            </div>
            <!-- Color picker -->
            <div v-if="$parent.selectedData.colorpicker" class="colorpicker">
                <div v-for="color in $parent.selectedData.colors" :key="color">
                    <div @click="color.selected = !color.selected " :class="{color:true,selectedColor:color.selected}"  >
                        <div :style="{background:color['colorHexTop']}"> </div>
                    </div>
                </div>
            </div>
            <!-- SINGLE -->
            <div v-if="this.$parent.selectedData['view'] == 'single'" class="content-wraper"  >
                <div class="content-main other" >
                    <!-- Bricks -->
                    <div class="bricks" >
                        <div v-for="item in filtredData(data['data'])" :key="item['title']" @click="selectItem(item,data['data'])" :class="{brick:true, selectedBrick: item['selected'] }"  >
                                <div class="picked" v-if="item['selected']">
                                    <i class="pi pi-check"></i>
                                </div>
                                <div v-if="item['pictogramFullPath' ] != undefined" class="brick-img"  >
                                    <div class="brick-image-container">
                                        <img class="brick-image" :src="item[  'pictogramFullPath' ] "  />
                                    </div>
                                    <div class="brick-image-title">{{ item[  this.$parent.selectedData['nameSelector']  ]  }}</div>
                                </div>
                                <div v-else class="brick-text">
                                    <p   >{{ item[  this.$parent.selectedData['nameSelector']  ]  }}</p>
                                </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- DOUBLE -->
            <div v-if="this.$parent.selectedData['view'] == 'multi'"  >
                <div v-for="section in data['data']" :key="section['name']" class="content-main other no-padding" >
                    <div :class="{'section-title':true , 'no-bottom-top': section['id'] == 1 }"> {{ section['name'] }} </div>  
                    <div class="bricks multi-section" >
                        <div    v-for="item in filtredData(section['data'])" :key="item" 
                                @click="selectItem(item,section['data'])" 
                                :class="{brick:true, selectedBrick: item['selected'] }">
                                
                                <div class="picked" v-if="item['selected']">
                                    <i class="pi pi-check"></i>
                                </div>
                                <div v-if="item['pictogramFullPath' ] != undefined" class="brick-img"  >
                                    <div class="brick-image-container">
                                        <img class="brick-image" :src="item[  'pictogramFullPath' ] "  />
                                    </div>
                                    <div class="brick-image-title">{{ item[  this.$parent.selectedData['nameSelector']  ]  }}</div>
                                </div>
                                <div v-else class="brick-text">
                                    <p   >{{ item[  this.$parent.selectedData['nameSelector']  ]  }}</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete';

export default {
  name: 'SideNav',
  components:{
      AutoComplete,
  },
  props: {
      title:String,
      mainView:Boolean,
      data:Object,
  },
  data(){
      return {
          selectedItem:null,
          filteredItems:null,
          query:'',
          items: Array.from({ length: 1000 }, (_, i) => ({ label: `Item #${i}`, value: i })),
      }
  },
  computed:{
      elements(){
          return this.$parent.elements
      }
  },
  mounted(){
  },
  methods:{
    searchItems(event){
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let query = event.query;
        let filteredItems = [];
        
        this.query = query

        for(let i = 0; i < this.data['data'].length; i++) {
            let item = this.data['data'][i];
            if ( item[ this.$parent.selectedData['nameSelector'] ].toLowerCase().includes( query.toLowerCase() )) {
                item.label = item[ this.$parent.selectedData['nameSelector'] ]
                filteredItems.push(item);
            }
        }

        // this.filtredData['data'] = this.data['data'].filter(element => {
        //     return element['familyName'].toLowerCase().includes( query.toLowerCase() )
        // })

        this.filteredItems = filteredItems;
    },
    async selectItem(item, data){
        // if( item['selected'] ){
        //     return 0
        // }
        
        // Family 
        if( this.$parent.selectedData.title == 'Family'){

            this.$parent.reciveFamilyName = item['familyName']
            this.$parent.selectFamily( item )

            if( this.$parent.firstPickFamiltFlagFix || this.$parent.selectionFromJson  ){

                this.$parent.selectionFromJson = false
                this.$parent.firstPickFamiltFlagFix  = true
                this.$parent.$refs.myScene.sceneIsBussy  = true

                await this.$parent.resetSelection()
            }else{
                this.$parent.firstPickFamiltFlagFix  = true

            }
            
        }

        data.forEach(element => {
            if( element == item ){
                element['selected'] = true
                this.$parent.selectedData.chips = [ item[ this.$parent.selectedData['nameSelector'] ] ] 
            }else{
                element['selected'] = false
            }
        });

        // Elements
        if( this.$parent.selectedData.title == 'Elements' ){
            var path = item['modelFullPath'].split('models/')[0]+'models/'
            var modelObject = item['modelFullPath'].split('models/')[1]

            if( this.$parent.$refs.myScene.numberOfAllInstances <= 1 && this.$parent.$refs.myScene.sideToExtend == null ){
                this.$parent.resetSelection( path , modelObject , item['furnitureTypeSymbol'], item )
            }else{
                this.$parent.addModel( path , modelObject , item['furnitureTypeSymbol'] , item )
            }
            item['selected'] = false


        }

        // Legs
        if( this.$parent.selectedData.title == 'Legs' ){
            await this.$parent.changeLegsTexture()
            this.$parent.refreshPrice()

        }

        // Upholstery
        if( this.$parent.selectedData.title == 'Upholstery' ){
            this.$parent.changeUpholstery()
        }
        // Armrest
        if( this.$parent.selectedData.title == 'Armrests' ){
            
            this.$parent.$refs.myScene.changeArmrestModel()
        }
        
        // Accessories
        if( this.$parent.selectedData.title == 'Accessories'){
            var path = item['modelFullPath'].split('models/')[0]+'models/'
            var modelObject = item['modelFullPath'].split('models/')[1]
            var accessoryModelName = item['meshName']
            var furnitureTypeSymbol = item['furnitureTypeSymbol']
            var accessoryType = item['accessoryType']
            var isRightExtend = item['isLeftExtend']

            var addingStatus = await this.$parent.$refs.myScene.addAccessory( path , modelObject, accessoryModelName, furnitureTypeSymbol, accessoryType, isRightExtend, item )
            item['selected'] = false
            this.$parent.pickedAccessory = item
            if( addingStatus ){
                this.$parent.addModelToControls(item)
            }
        }

    },
    filtredData(dataToFilter){

        // filter elements by extended side
        if( this.$parent.selectedData.title == 'Elements'){
            if( this.$parent.$refs.myScene.sideToExtend == 'LEFT' ){
                dataToFilter = dataToFilter.filter(el=>{
                    return el.isRightExtend
                    // return el
                })
            }
            if( this.$parent.$refs.myScene.sideToExtend == 'RIGHT' ){
                dataToFilter = dataToFilter.filter(el=>{
                    return el.isLeftExtend
                    // return el
                })
            }
        }

        var data = {}
        if( this.selectedItem instanceof Object ){
            data['data'] = this.selectedItem
        }else{
        if( typeof this.selectedItem === 'string'  &&  this.selectedItem.length > 0 ){
            data = dataToFilter.filter(element => {
                return element[ this.$parent.selectedData['nameSelector'] ].toLowerCase().includes( this.selectedItem.toLowerCase() )
            })
        }else{
            data = dataToFilter
        }
        }

        return data
    }

  }
}
</script>

<style scoped>
.pi{
    cursor: pointer;
    font-weight: 700 !important;
}
.no-padding{
    padding: 0 !important;
}
.section-title{
    border-bottom:solid 2px #e1e1e1;
    border-top:solid 2px #e1e1e1;
    padding: 6%;
}
.no-bottom-top{
    border-top: unset !important;
}
.selectedBrick{
    border: 2px solid black;
}
.bricks{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 125px);
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    display: grid;
    align-content: stretch;
    justify-content: space-evenly;
    padding: 6%;
    padding-top:0% !important
}
.brick{
    border: 2px solid #CCCCCC;
    border-radius: 10px;
    width: 125px;
    height: 125px;
    cursor: pointer;
    position: relative;
}
.brick-text{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: -webkit-fill-available;
}
.brick-img{
    width: inherit;
    height: inherit;
}
.brick-image-container{
    height: 75%;
}
.brick-image{
    height: inherit;
    width: 90%;
    margin-top: 5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: none;
}
.brick-image-title{
    font-size: .8rem;
}
.bricks:after{ 
    content:' '; display:inline-block; 
    width: 100%; 
    height: 0 
}
.multi-section{
    padding-top: 6% !important;
}

.picked{
    position: absolute;
    width: 10px;
    height: 10px;
    background: black;
    right: 5px;
    top: 5px;
    border-radius: 50%;
    padding:3px;
}
.picked > i{
font-size: .6rem;
    color: white;
    display: block;
    margin-top: 2px;
}
.right{
    text-align: end;
}
.thin{
    font-weight: 100;
}
.sidenav-content{
    font-weight: 600;
    width: 100%;
    z-index:-1;
    
}
.content-title{
    padding: 6%;
    display: flex;
    border-bottom:solid 2px #e1e1e1;
    color: #9699A0
}
.content-title > div{
    padding-right: 5%;
    text-align: left;
}
.borderless{
    border-left: unset !important;
}
.title{
    width: 90%;
    color: #404545;
    size:16px;
    text-align: left;
}
.content-main{
    padding: 5%;
    border-bottom:solid 2px #e1e1e1;
    position: relative;
    
}
.main-page{
    padding-left: 10%;
}
.content-main-main{
    width: 100%;
    display: flex;
}
.main-chips{
    padding-top: 2%;
    width: 85%;
    display: flow-root;
    color: white;
}
.chip{
    background: #2E3341 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
    padding: 8px;
    margin: 1% 1% 0 0;
    font-weight: 100;
    width: fit-content;
    float: left;
}
.content-main-forward-icon{
    position: absolute;
    top:calc(50% - 8px);
    left: 88%;
}
.search-element{
    margin: 5%;
    padding: 0;
}
input{
    border:unset !important;
}
input:focus-visible{
    outline:none;
}
.p-autocomplete{
    width: 100%;
}
.p-autocomplete-panel{
    box-shadow: 0px 3px 25px #00000017 !important;
    background:#F9F9FB;
}
.p-autocomplete-input {
    width: 100% !important;
    padding-left: 5%;
    padding-bottom: 2%;
}
.p-autocomplete-item{
    text-align: center;
    padding: 2px;
    color: grey;
}
.p-inputtext{
    font-size: 16px !important;
}
.colorpicker{
    display:flex;
    justify-content:space-around;
    margin-bottom: 5%;
    
}
.color{
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
    padding: 1px;
    border: 2px solid #D9DDE5;
    border-radius: 5px;
    
}
.selectedColor{
    border: 2px solid #2E3341;
    border-radius: 5px;
}
.color > div{
  padding: .7vw;
  border: 1px solid white;
  border-radius: 5px
}
.content-wraper{
    position: relative;
}
.other{
    border: none;
    overflow-y: auto;
}
.elements{
    width:50%;
}
.elements-items{
    display:flex;
    font-weight: 100;
    padding:4px;
    
}
.elements-title{
    text-align:left;
    padding-bottom: 3px;
}
.element-lp{
    width: 15%;
    text-align:left;
}
.element-name{
    width: 75%;
    text-align:left;
}
.element-times{
    width: 10%;
    color: grey;
}
</style>
