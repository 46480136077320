// import { createApp } from 'vue'
// import App from './App.vue'
// import PrimeVue from 'primevue/config';

// const app = createApp(App)
// app.use(PrimeVue)
// app.mount('#app')


import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';
import { setPublicPath } from 'systemjs-webpack-interop';
setPublicPath('@svelte-react-vue-angular-spa/vue-cw');

const vueLifecycles = singleSpaVue({
    createApp,
    appOptions: {
        render() {
            return h(App, {
                // single-spa props are available on the "this" object. Forward them to your component as needed.
                // https://single-spa.js.org/docs/building-applications#lifecyle-props
                // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
                /*
                name: this.name,
                mountParcel: this.mountParcel,
                singleSpa: this.singleSpa,
                */
            });
        },
    },
});


export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;