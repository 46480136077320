<template>
<div class="summary-component-content" >
    <div class="summary-title">
        <div class="title grey thin" >
            Configuration summary
        </div>
        <div id="close-icon" >
            <i @click="hideSummary()" class="pi pi-times black"></i>
        </div>
    </div>
    <div class="summary-up">
        <div class="summary-left one-side">
            <div class="left-summary-up">
                <div class="summary-values" > #89289349</div>
                <span class="summary-titles" > Configuration No</span>
            </div>
            <div class="left-summary-up">
                <div class="summary-values" > 1,192.00 $</div>
                <span class="summary-titles" > Total price</span>
            </div>
        </div>
        <div class="summary-right one-side">
            <div class="summary-section-title">
                <div class="section-t" >Family</div>
                <div class="section-t" >Upholstery</div>
                <div class="section-t" >Armrest</div>
            </div>
            <div class="summary-brick" >
                <div class="bricks" >
                    <div class="brick">
                        <div class="brick-text">
                            <p> {{ $parent.selectedFamily.familyName }} </p>
                        </div>
                    </div>
                    <div class="brick">
                        <div class="brick-img"  >
                            <div class="brick-image-container">
                                <img class="brick-image" :src="$parent.selectedUpholstery[  'pictogramFullPath' ] "  />
                            </div>
                            <div class="brick-image-title"> {{ $parent.selectedUpholstery.textileName }}  </div>
                        </div>
                    </div>
                    <div class="brick">
                        <div class="brick-img"  >
                            <div class="brick-image-container">
                                <img class="brick-image" :src="$parent.selectedArmrest[  'pictogramFullPath' ] "  />
                            </div>
                            <div class="brick-image-title"> {{ $parent.selectedArmrest.armrestTypeSymbol }}  </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="summary-down">
        <div class="summary-left one-side">
            <div class="summary-elements">
                <div class="elements-title">
                    Elements
                </div>
                <div v-for="item in elements" :key="item.id" class="elements-items">
                    <div class="element-lp-summary" >
                        {{ elements.indexOf(item)+1 }}
                    </div>
                    <div class="element-name-summary">
                        {{ item.name }}
                    </div>
                    <div class="element-times-summary">
                        x{{ item.times }}
                    </div>
                    <div class="element-price-summary">
                        {{ 123 }} $
                    </div>
                    
                </div>
            </div>
            <div class="summary-accessories">
                <div class="elements-title">
                    Accessories
                </div>
                None
                <div v-for="item in accesories" :key="item.id" class="elements-items">
                    <div class="element-lp-summary" >
                        {{ elements.indexOf(item)+1 }}
                    </div>
                    <div class="element-name-summary">
                        {{ item.name }}
                    </div>
                    <div class="element-times-summary">
                        x{{ item.times }}
                    </div>
                    <div class="element-price-summary">
                        {{ 123 }} $
                    </div>
                </div>
            </div>
        </div>
        <div class="summary-right one-side">
            <div class="summary-section-title">
                <div class="section-t" >Legs</div>
            </div>
            <div class="summary-brick" >
                <div class="bricks" >
                    <div class="brick">
                        <div class="brick-img"  >
                            <div class="brick-image-container">
                                <img class="brick-image" :src="$parent.selectedLegs[  'pictogramFullPath' ] "  />
                            </div>
                            <div class="brick-image-title"> {{ $parent.selectedLegs.legSymbol }}  </div>
                        </div>
                    </div>
                    <div class="brick">
                        <div class="brick-img"  >
                            <div class="brick-image-container">
                                <img class="brick-image" :src="$parent.selectedLegsColor[  'pictogramFullPath' ] "  />
                            </div>
                            <div class="brick-image-title"> {{ $parent.selectedLegsColor.colorName }}  </div>
                        </div>
                    </div>
                    <div class="brick">
                        <div class="brick-text">
                            <p> {{ $parent.selectedLegsHeight.heightSymbol }} </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summary-section-title">
                <div class="section-t" >Comfort</div>
                <div class="section-t" >Cover</div>
            </div>
            <div class="summary-brick" >
                <div class="bricks" >
                    <div class="brick">
                        <div class="brick-text">
                            <p> {{ $parent.selectedComfort.comfortName }} </p>
                        </div>
                    </div>
                    <div class="brick">
                        <div class="brick-text">
                            <p> {{ $parent.selectedCover.coverName }} </p>
                        </div>
                    </div>
                    <div class="brick blank">
                        <div class="brick-text">
                        </div>
                    </div>
                </div>
            </div>

            <div class="summary-qrcode">
                <div class="qr-content" >
                    <div class="qr-code">
                        <img src="https://www.chip.pl/uploads/2012/02/58a3828038b9bd0da97f3c1de2c873e9.png" alt="">
                    </div>
                    <div class="qr-congfig">
                        <div class="config">Configuration code</div>
                        <span class="code-number">45T33R</span>
                    </div>
                    <div class="qr-coppy-to-clip">
                        <i class="pi pi-copy"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>
        

</div>

</template>

<script>

export default {
  name: 'Summary',
  data() {
    return {
    }
  },
  computed:{
      elements(){
          return this.$parent.elements
      }
  },
  methods:{
      hideSummary(){
          this.$parent.hideSummary()
      }
  }
}
</script>

<style scoped>
.qr-content{
    margin-left: 10%;
    margin-top: 7%;
}
.qr-coppy-to-clip{
    width: 7%;
    float: left;
    font-size: 22px;
    border: solid 2px #e1e1e1;
    border-radius: 5px;
    padding: 2px;
}
.qr-congfig{
    width: 60%;
    float: left;
    text-align: left;
}
.qr-congfig > span{
    color:black
}
.qr-code{
    width: 15%;
    float: left;
}
.qr-code > img{
    max-width: 40px;
}
.summary-qrcode{
    border-top: solid 2px #e1e1e1;
    margin-left: -10px;
}
.summary-section-title{
    width: 100%;
    display: flex;
}
.section-t{
    width: 30%;
    text-align: left;
    padding: 0 0 2% 3%;

}
.summary-title{
    padding: 3%;
    display: flex;
    border-bottom:solid 2px #e1e1e1;
    color: #9699A0
}
.summary-up{
    display: flex;
    border-bottom:solid 2px #e1e1e1;
    color: #9699A0
}
.summary-down{
    display: flex;
    border-bottom:solid 2px #e1e1e1;
    color: #9699A0;
    height: 60vh;
}
.summary-right{
    float: right;
    border-left:solid 2px #e1e1e1;
    padding: 4% 0 0% 1%
}
.summary-left{
    text-align: left;
    padding: 4% 5% 0% 5%;
}
.one-side{
    width: 50%;
    
}
.left-summary-up{
    padding-bottom: 7%;
}
.left-summary-up > div{
    color: black;
    font-size: 3vh;
    padding-bottom: 5px;
}
.selectedBrick{
    border: 2px solid black;
}
.bricks{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 125px);
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    display: grid;
    align-content: stretch;
    justify-content: space-evenly;
    padding: 6%;
    padding-top:0% !important
}
.brick{
    border: 2px solid #CCCCCC;
    border-radius: 10px;
    width: 120px;
    height: 120px;
    cursor: unset;
    position: relative;
}
.brick-text{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: -webkit-fill-available;
}
.brick-img{
    width: inherit;
    height: inherit;
}
.brick-image-container{
    height: 75%;
}
.brick-image{
    height: inherit;
    width: 90%;
    margin-top: 5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: none;
}
.brick-image-title{
    font-size: .8rem;
}
.bricks:after{ 
    content:' '; display:inline-block; 
    width: 100%; 
    height: 0 
}
.summary-elements{
    min-height: 21vh;
}
.elements-title{
    padding-bottom: 5%;
}
.elements-items > div{
    color:black;
}
.element-lp-summary{
    width: 15%;
    text-align:left;
}
.element-name-summary{
    width: 55%;
    text-align:left;
}
.element-times-summary{
    width: 20%;
    color: grey;
}
.blank{
    border-color: white !important;
}
</style>
